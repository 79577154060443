import { Link } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";
import Image from "react-bootstrap/Image";
import { selectLangState } from "../../redux/configurationSlice";
import { useSelector } from "react-redux";
import { AuthAPI } from "../../apis/authApi";
import { MoengageService } from "../../services/moengage";

const CardFavourite = ({
  to,
  imageUrl,
  offerTitle,
  validity,
  offerType,
  discount,
  rating,
  isFavorite = false,
  tags = [],
  outlet = null,
  refetch = () => {},
}) => {
  const { lang } = useSelector(selectLangState);

  const removeFavorites = async () => {
    try {
      const data = {
        company: "ESD",
        language: lang?.startsWith("ar") ? "ar" : "en",
        platform: "website",
        outlet_id: outlet?.id,
      };
      await AuthAPI.removeFavorites(data);
      MoengageService.removeFromFavourite({
        screen_name: "Favourite",
        merchant_name: outlet?.merchant_name,
      });
      refetch();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="src-Card hrItem d-flex">
      <div className="imgHolder rounded overflow-hidden me-3">
        <Link to={to}>
          <Image
            src={imageUrl}
            alt="image"
            className="img-fluid d-block w-100 h-100 object-fit-cover"
          />
        </Link>
      </div>
      <div className="text">
        <div className="head d-flex align-items-start">
          <h3 className="font-bukra">
            <Link to={to} className="primary-color text-decoration-none">
              {offerTitle}
            </Link>
          </h3>
          <span
            className={`favIcon d-flex align-items-center justify-content-center rounded-circle ${
              isFavorite && "bg-primary"
            }`}
            onClick={removeFavorites}
          >
            <svg
              width="18"
              height="16"
              viewBox="0 0 18 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.3836 1.55161C15.4708 0.60137 14.1918 0.0606976 12.8517 0.0586932C11.5106 0.0601965 10.2303 0.600582 9.31608 1.551L9.00451 1.86987L8.69295 1.551C6.87896 -0.339921 3.8257 -0.448513 1.87339 1.3084C1.78688 1.38629 1.70332 1.46717 1.62291 1.551C-0.301387 3.56129 -0.301387 6.66809 1.62291 8.67838L8.54665 15.7502C8.78619 15.9951 9.18537 16.0056 9.43825 15.7736C9.44653 15.766 9.45458 15.7582 9.46242 15.7502L16.3836 8.67838C18.3078 6.6683 18.3078 3.56168 16.3836 1.55161ZM15.471 7.83723H15.4704L9.00451 14.4423L2.53805 7.83723C1.068 6.30119 1.068 3.92758 2.53805 2.39154C3.87304 0.990141 6.12826 0.902272 7.57518 2.19525C7.64543 2.25803 7.71302 2.3235 7.77784 2.39154L8.54665 3.1771C8.80001 3.42091 9.20906 3.42091 9.46242 3.1771L10.2312 2.39214C11.5662 0.990749 13.8214 0.90288 15.2683 2.19586C15.3386 2.25864 15.4062 2.3241 15.471 2.39214C16.9538 3.93062 16.9647 6.30856 15.471 7.83723Z"
                fill="white"
              />
              <path
                d="M1.00483 4.99974C0.604827 -0.600264 6.17149 0.999736 9.00483 2.49974C17.0056 -2.30028 17.3379 4.16641 16.5039 7.99976L9.00483 14.9998C6.50483 13.9998 2.50391 9.49976 1.00483 4.99974Z"
                fill="white"
              />
            </svg>
          </span>
        </div>
        <p>{validity}</p>
        <div className="d-flex align-items-center justify-content-between">
          {tags?.length > 0 && (
            <Stack direction="horizontal" gap={2}>
              {tags?.map((tag, key) => (
                <Badge
                  pill
                  key={key}
                  bg={tag?.text === "Featured" ? "light" : "primary"}
                  text={tag?.text === "Featured" ? "dark" : "white"}
                  className={tag?.text !== "Featured" && "dir-ltr"}
                >
                  {tag?.text}
                </Badge>
              ))}
            </Stack>
          )}
          <div className="rating d-flex align-items-center">
            <Image src="/images/Star.svg" alt="Star" className="me-1" />
            <span className="font-bukra fw-700 text-black">{rating}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardFavourite;
