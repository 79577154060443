import PageBanner from "../Components/Global/PageBanner";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageBanner
        imageUrl="/images/bg-pageBnr.png"
        title={`${t("Privacy Policy")}`}
      />
      <div className="contentHolder add contentPage">
        <Container>
          <Row>
            <Col xs={12}>
              <div className="textBlock">
                <h2 className="fw-700 font-bukra text-black">
                  {t("Overview")}
                </h2>
                <p>
                  {t(
                    'ESAAD is committed to protecting the rights of visitors to its website and to maintaining the confidentiality of personal information that is used in this Website. Privacy Policy aims to clarify the approach of ESAAD, and its obligations; with respect to the collection, use, distribution, retention, destruction and dissemination (collectively called, "use" or "usage" or their derivatives) of personal information or data (collectively called, "data") which is collected on ESAAD eService’s Website at'
                  )}{" "}
                  <Link
                    to={"https://esaad.dubaipolice.gov.ae"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    esaad.dubaipolice.gov.ae
                  </Link>
                </p>
              </div>
              <div className="textBlock">
                <h2 className="fw-700 font-bukra text-black">
                  {t("Policy Statement")}
                </h2>
                <p>
                  {t(
                    "ESAAD respects the privacy of visitors (namely, “users”) to its website by remaining committed to ensuring the protection of user's privacy, and therefore it collects and manages all data provided by users in a very confidential manner and utmost care where information is not exposed to any third parties, unless the law requires so. ESAAD will not be using the data provided by users in ways that are not agreed upon by users or in illegal ways."
                  )}
                </p>
                <p>
                  {t(
                    "We will not collect any personal information, when you visit our website, except when you provide it, voluntarily, through your participation in activities that require information when registering with Esaad website"
                  )}
                </p>
                <p>
                  {t(
                    "Any data required from the user, in this Website, will be used, exclusively, in accordance with the privacy policy set forth below. ESAAD will never ask for any unnecessary data."
                  )}
                </p>
                <p>
                  {t(
                    'ESAAD Website includes public pages that can be browsed by all users, without having to provide any personal data except for data collected by cookies when used (see details below). The Site also contains secured pages that require registration to be accessed, using the form "request a user registration", in the open public pages.'
                  )}
                </p>
              </div>
              <div className="textBlock">
                <h2 className="fw-700 font-bukra text-black">
                  {t("Policy Scope")}
                </h2>
                <p>
                  {t(
                    "Privacy Policy, set forth in this document, applies to ESAAD Website (esaad.dubaipolice.gov.ae) and to the information collected by this Site only."
                  )}
                </p>
                <p>
                  {t(
                    "Esaad Website contains links to other websites that are provided only as service information, and they are subject to their own privacy policy. Esaad is not responsible for any content available at these sites. Therefore, we advise you to have access to the privacy policies of those websites, which you visited through Esaad Website to determine the site-based practices there."
                  )}
                </p>
                <p>
                  {t(
                    "Users should note that Esaad does not promote these sites neither it is sure of the contents or the products and services they offer. Esaad shall not exercise any authority whatsoever over the sites that are not owned or managed by it. Esaad is not responsible for protecting any information/data that the user provides when visiting those sites, including information security and privacy practices of these sites."
                  )}
                </p>
              </div>
              <div className="textBlock">
                <h2 className="fw-700 font-bukra text-black">
                  {t("Collection and Storage of Information Automatically")}
                </h2>
                <p>
                  {t(
                    "Dear user, when browsing Esaad Website, we will collect and store certain information about your visit, automatically, while, at the same time, it does not identify you personally. We do automatically collect and store information about your visit, e.g.: Internet Protocol (IP) your computer (which is a number that is assigned automatically to your computer at any time you browse the Internet), used web browser and operating systems, which websites you moved from to our website, date and time of your access to our website, and pages visited. We use this information to help us make our Site more useful to visitors, to find out the number of visitors to our Site, and the type of technology used by our visitors. We do not track down or record any information that relate to the individuals and to their visits."
                  )}
                </p>
                <p>
                  {t(
                    "When you visit certain parts of this Site, you may be asked to provide personal information to make sure of your identity (such as name, address, email, emirates id), all of which are necessary to complete your registration. However, if you refuse to provide such information, you limit our ability to serve you. However, in any case, we continue to invite you to visit our Site and take advantage of the valuable information we offer."
                  )}
                </p>
                <p>
                  {t(
                    "Esaad Site uses cookies to follow the users while browsing only to collect and analyse usage statistics for development and improvement and providing users with the best experience of our Site. Use of cookies is usual practice in the electronic websites. Cookies are simple text files stored in your personal computer by the web browser. It provides a distinction tool between site visitors. Cookies do not contain any personal identification number (PIN) or subject your privacy or information to any risk. It is possible to set most web browsers to reject cookies, accept, or delete these files. However, the refusal or removing cookies may obstruct those to run complete and faster."
                  )}
                </p>
              </div>
              {/* <div className="textBlock">
                <h2 className="fw-700 font-bukra text-black">
                  {t("Updating Personal Information")}
                </h2>
                <p>
                  {t(
                    "To the Esaad website visitor ( esaad.dubaipolice.gov.ae), when registering on the website, you will be required to provide specific identification information on the registration screen. This will be limited to the minimum required information to complete the registration, such as your Emirates ID number, name, nationality, date of birth, email address, and phone numbers."
                  )}
                </p>
                <p>
                  {t(
                    "Some of the required information is optional, and this will be clarified while filling out the form. You can review and make changes to the personal information you’ve submitted and saved at any time by logging into your account on the website and updating your preferences on the profile page."
                  )}
                </p>
                <p>
                  {t(
                    "This is aimed at providing better services to our visitors and making it easier to fill out forms and access personal data."
                  )}
                </p>
              </div> */}
              <div className="textBlock">
                <h2 className="fw-700 font-bukra text-black">
                  {t("Protection of personal information")}
                </h2>
                <p>
                  {t(
                    "For an effective and efficient service, Esaad collects some specific data of users, in certain places within the Site. Esaad is committed to maintain the confidentiality of all the information collected from users and will not allow accessing them but to the authorized government officials and within the limits of what they need to know only. Esaad needs the data that enable it to understand the needs of users and provide them with the correct service, accurately and completely. Hence, you must ensure that the information is complete and accurate and that you will not attempt to use the Site using someone else’s name. We will not provide any information you give us to any private organizations or persons, from the private sector. Esaad does not collect or use information for commercial marketing."
                  )}
                </p>
                <p>
                  {t(
                    "Dear user, if you wish to provide us with information about you by sending an email or fill the form for your personal information and submit it to us, via our website, we use these information to respond to your message and to help us provide you with the information you requested. If you send your data summary across our website, we use your personal data for employment purpose only and not for other purposes."
                  )}
                </p>
                <p>
                  {t(
                    "In case you want to close your account or if someone has stolen your account, you can report that to our Call Center (901), around the clock, or communicate your concern via email: mail@dubaipolice.gov.ae. Your account will be closed after confirming your identity, but the personal information of the account will remain in our database to deter fraud by ensuring that persons who try to commit fraud will not be able to escape detection attempt, by closing their accounts and opening new accounts. However, if you close your account, we will not use your personal information for any other purpose and will not sell or share any with third parties, unless the law requires it."
                  )}
                </p>
              </div>
              <div className="textBlock">
                <h2 className="fw-700 font-bukra text-black">
                  {t("Information Security")}
                </h2>
                <p>
                  {t(
                    "Esaad Site is protected by multiple security measures, such as documentation, monitoring, auditing, and encryption. Esaad uses a variety of other mechanisms, security measurements; and technical, regulatory, and reasonable precautions; in the design and implementation of the daily operations. We continue our engagement with the electronic content and the electronic transfer of information to make sure that the data provided by users are not misused or modified or lost. These measurements include control, regular storage backup, and applying of database security policies."
                  )}
                </p>
                <p>
                  {t(
                    "This Site takes all the necessary precautions to protect users’ information. When users submit sensitive information via the Website, all are protected, whether when in use or after they were used. While encryption is used to protect sensitive information when in use, we do everything in our power to protect all information in the case of non-use. All electronic transactions performed by users, with Esaad, are subjected to the use of Secure Sockets Layer technology (SSL)."
                  )}
                </p>
                <p>
                  {t(
                    "Esaad and would like to draw the attention of users to the fact that the data sent over the Internet is unsecured in nature, and therefore Esaad does not guarantee that data sent over the Internet are secured. Users are responsible for maintaining the confidentiality of their own users’ names and passwords. Esaad will not request any personal password unless user would like to use it to access a service or secured pages."
                  )}
                </p>
              </div>
              <div className="textBlock">
                <h2 className="fw-700 font-bukra text-black">
                  {t("Amendment of Privacy Policy")}
                </h2>
                <p>
                  {t(
                    "Esaad may make some adjustments in the Privacy Policy to accommodate changes in the legal and regulatory requirements, business requirement for upgrading technologies and applications, or for service improvement. Esaad reserves the right to make any radical or slight modification of the content of this document at any time and without prior notice. Continue to use the Site means your acceptance of such changes. Please review this Policy periodically. In case of radical changes in this Policy, we will inform you via email or by means of a notice in our Homepage."
                  )}
                </p>
              </div>
              <div className="textBlock">
                <p>
                  <span className="fw-500">{t("Contact us")}</span> <br />
                  {t(
                    "If you have any questions or suggestions regarding our privacy policy, please contact us at {{email}}.",
                    { email: "esaad@dubaipolice.gov.ae" }
                  )}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PrivacyPolicy;
