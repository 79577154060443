import Form from "react-bootstrap/Form";
import InputLabel from "../../Components/Form/InputLabel";
import InputField from "../../Components/Form/InputField";
import CustomButton from "../../Components/Global/customButton";
import { Controller } from "react-hook-form";
import useApi from "../../hooks/useApi";
import { AuthAPI } from "../../apis/authApi";
import FieldValidationText from "../../Components/Global/fieldValidationText";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Image } from "react-bootstrap";
import ButtonWithLoading from "../buttons/ButtonWithLoading";
import useForm from "../../hooks/useForm";

const EmailVerification = ({ setState, state, setCurrent }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, invokeApi] = useApi();
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors, isDirty, isValid, isSubmitting },
    handleChange,
  } = useForm({
    mode: "all",
  });

  const onSubmit = async (values) => {
    try {
      const res = await AuthAPI.verifyEmail({ email: values.email });
      setCurrent(1);
      navigate("/setup-password", { state: { email: values.email } });
    } catch (error) {
      setError("emiratesId", {
        type: "manual",
        message: error?.message || "Something went wrong!",
      });
    }
  };
  return (
    <>
      <div className="bg-auth">
        <Image
          src="/images/bg-auth.png"
          alt="icon-uae-pass"
          className="d-inline-block align-middle me-2"
        />
      </div>
      <div className="auth d-flex align-items-center justify-content-center">
        <div className="center-content w-100">
          <h1 className="fw-700 text-white font-bukra">
            {t("Enter your email")}
          </h1>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3">
              <InputLabel
                className="font-dubai text-white"
                text="Email address"
              />
              <Controller
                name="email"
                control={control}
                rules={{
                  required: t("Email is required"),
                }}
                render={({ field }) => (
                  <InputField
                    className="font-dubai text-white"
                    placeholder="John.d@gmail.com"
                    {...field}
                    onChange={(e) => handleChange(e)}
                  />
                )}
              />
              {errors.email && (
                <FieldValidationText errorMessage={errors.email?.message} />
              )}
            </Form.Group>
            <ButtonWithLoading
              variant="primary"
              type="submit"
              className="fw-500 font-dubai d-block w-100 mb-3 mb-md-0"
              text={t("Next")}
              disabled={!isValid || !isDirty || isSubmitting || loading}
              isLoading={loading}
            />
          </Form>
        </div>
      </div>
    </>
  );
};

export default EmailVerification;
