import { Link, useNavigate } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";
import Image from "react-bootstrap/Image";
import { t } from "i18next";

const HighlightText = ({ text, searchText }) => {
  if (!searchText) {
    return <span>{text}</span>;
  }

  const parts = text.split(new RegExp(`(${searchText})`, "gi"));

  return (
    <span>
      {parts.map((part, index) =>
        part.toLowerCase() === searchText.toLowerCase() ? (
          <strong key={index}>{part}</strong>
        ) : (
          part
        )
      )}
    </span>
  );
};

const SearchCard = ({
  to,
  imageUrl,
  offerTitle,
  address,
  offerType,
  discount,
  distance,
  rating,
  tags = [],
  searchText,
  setIsSearchFocused,
  setSearchText,
}) => {
  const navigate = useNavigate();
  const handleNavigate = (e) => {
    e.stopPropagation();
    e.preventDefault();
    navigate(to);
    setIsSearchFocused(false);
    setSearchText("");
  };
  return (
    <div className="src-Card d-flex">
      <div className="imgHolder">
        <div onClick={handleNavigate} className="cursor-pointer">
          <Image
            src={imageUrl}
            alt="image"
            className="img-fluid d-block w-100"
          />
          <Badge pill bg="light" text="dark" className="distance dir-ltr">
            {distance} {t("km")}
          </Badge>
        </div>
      </div>
      <div className="text">
        <div className="head d-flex align-items-start">
          <h3 className="font-bukra">
            <span
              onClick={handleNavigate}
              className="primary-color text-decoration-none cursor-pointer"
            >
              <HighlightText text={offerTitle} searchText={searchText} />
            </span>
          </h3>
          <div className="rating d-flex align-items-center ms-auto">
            <Image src="/images/Star.svg" alt="Star" className="me-1" />
            <span className="font-bukra fw-400">{rating}</span>
          </div>
        </div>
        <p>{address}</p>
        {tags?.length > 0 && (
          <Stack direction="horizontal" gap={2}>
            {tags?.map((tag, key) => (
              <Badge
                pill
                key={key}
                bg={tag?.text === "Featured" ? "light" : "primary"}
                text={tag?.text === "Featured" ? "dark" : "white"}
              >
                {tag?.text}
              </Badge>
            ))}
          </Stack>
        )}
      </div>
    </div>
  );
};

export default SearchCard;
