import PageBanner from "../Components/Global/PageBanner";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const TermsAndCondition = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageBanner
        imageUrl="/images/bg-pageBnr.png"
        title={`${t("Terms & Conditions")}`}
      />
      <div className="contentHolder add contentPagecontentPagecontentPage">
        <Container>
          <Row>
            <Col xs={12}>
              <div className="textBlock">
                <h2 className="fw-700 font-bukra text-black">
                  {t("Introduction")}
                </h2>
                <p>
                  {t(
                    "By accessing the content of Esaad site (esaad.dubaipolice.gov.ae) you agree to be bound by the terms and conditions set out herein and you accept our privacy policy: ESAAD Privacy Policy. If you object to any of the terms and conditions set out in this agreement, please do not use this web site."
                  )}
                </p>
                <p>
                  {t("By visiting Esaad Web site, you agree to the following:")}
                </p>
              </div>
              <div className="textBlock">
                <h2 className="fw-700 font-bukra text-black">
                  {t("Copyrights")}
                </h2>
                <p>
                  {t("Copyright ©2024 ESAAD, All Rights Reserved.")}
                  <br />
                  {t(
                    "Any rights not expressly granted herein are reserved. No copyrighted material may be distributed, downloaded, modified, reused, copied, reproduced, transferred, displayed, reposted, transmitted, disseminated, sold, published, broadcast or circulated or otherwise used except as expressly stated either in such materials or in this notice without the express written permission of ESAAD."
                  )}
                </p>
              </div>
              <div className="textBlock">
                <h2 className="fw-700 font-bukra text-black">
                  {t("Trademarks")}
                </h2>
                <p>
                  {t(
                    "The marks or logos appearing through this web site are proprietary of ESAAD. You are prohibited from using any of the marks or logos without permission from ESAAD, except as permitted by applicable law."
                  )}
                </p>
              </div>
              <div className="textBlock">
                <h2 className="fw-700 font-bukra text-black">
                  {t("Possible Errors and Revisions")}
                </h2>
                <p>
                  {t(
                    "The information, materials, software, products and services (collectively data) included in or available through ESAAD web site and services may be superseded and /or may include inaccuracies or typographical errors. Changes are periodically made to the date contained herein."
                  )}
                </p>
              </div>
              <div className="textBlock">
                <h2 className="fw-700 font-bukra text-black">
                  {t("Disclaimer of Warranties")}
                </h2>
                <p>
                  {t(
                    "ESAAD web site and material relating to ESAAD Information, products and services (or to third party information, products and services) is provided as is without any representation or endorsement made and without warranty of any kind, whether express or implied, including but not limited to the implied. Warranties of satisfactory quality, fitness for a particular purpose non-infringement, compatibility security and accuracy. The information on this site is provided for convince as part of the service we offer at this website."
                  )}
                </p>
              </div>
              <div className="textBlock">
                <h2 className="fw-700 font-bukra text-black">
                  {t("Disclaimer of Liability")}
                </h2>
                <p>
                  {t(
                    "In no event will ESAAD be liable for any damages whatsoever, but not limited to any direct incidental, consequential, special, exemplary or other indirect damages arising out of the use of or inability to use the site. The services or the content any transaction conducted through or facilitated by the site. Any claim attributable to errors, omissions, or other inaccuracies in the site."
                  )}
                </p>
                <p>
                  {t(
                    "The service and / or content unauthorized access to or alternation of your transmissions or data or any other matter relating to the site. The service or the content even if ESAAD has been advised of the possibility of such damages of losses. If you are dissatisfied the with site, the content, or with the term of user, you are sole and exclusive remedy is to discontinue using the site."
                  )}
                </p>
              </div>
              <div className="textBlock">
                <h2 className="fw-700 font-bukra text-black">
                  {t("Links to External Web Sites")}
                </h2>
                <p>
                  {t(
                    "Links on this Web Site to third party web sites or information are provided solely as a convenience to you. We will make every effort to clearly indicate that you are leaving ESAAD web site to go to a linked site but links on ESAAD web site will lead to other web sites which are not under our control, and you are subject to the terms of use and privacy policy applicable to those web sites. We are not responsible for the content of any linked site. We cannot guarantee that these links will work all the time and have no control over the availability of the linked pages. Visitors who rely on this information do so at their own risk."
                  )}
                </p>
              </div>
              <div className="textBlock">
                <h2 className="fw-700 font-bukra text-black">
                  {t("No Unlawful or Prohibited Use")}
                </h2>
                <p>
                  {t(
                    "As a condition of your use of ESAAD Web site, you warrant to ESAAD that you will not use ESAAD Web site for any purpose that is unlawful or that is prohibited by these terms of use and notices. You may not use ESAAD Web site in any manner which could damage, disable, overburden, or impair the Web site and servers. You may not use ESAAD Web site or servers to publish, post, distribute or disseminate any defamatory, obscene or otherwise unlawful material or information, including another's proprietary information, including trademarks or copyrighted information, without express authorization from the rights holder."
                  )}
                </p>
              </div>
              <div className="textBlock">
                <h2 className="fw-700 font-bukra text-black">{t("Privacy")}</h2>
                <p>
                  {t(
                    "ESAAD Web site, servers and computer system may be monitored for all lawful purposes, including to ensure that their use is authorized, to manage the Web site, server and computer system, to facilitate protection against unauthorized access, and to verify security procedures, survivability and operational security. All information (including without limitation, personal information and electronic communications) placed on or sent over this Web site, server or computer system may be monitored by ESAAD. The unauthorized use of the Web site, server or computer system may subject you to criminal prosecution."
                  )}
                </p>
                <p>
                  {t(
                    "Please read the ESAAD Web Site Privacy Policy that addresses other privacy issues associated with ESAAD Web site."
                  )}
                </p>
              </div>
              <div className="textBlock">
                <h2 className="fw-700 font-bukra text-black">
                  {t("Password Security")}
                </h2>
                <p>
                  {t(
                    "If you are registered with ESAAD Web Site for accessing online services, you are responsible for maintaining the confidentiality of your member identification and password information and for restricting access to your computer. You agree to accept responsibility for all activities that occur under your member identification and password."
                  )}
                </p>
              </div>
              <div className="textBlock">
                <h2 className="fw-700 font-bukra text-black">
                  {t("Virus protection")}
                </h2>
                <p>
                  {t(
                    "We make every effort to check and test material at all stages of production. It is always wise for you to run an anti-virus program on all material downloaded from the internet. We cannot accept any responsibility for any loss, disruption or damage to your data or your computer system which may occur whilst using material derived from this website."
                  )}
                </p>
              </div>
              <div className="textBlock">
                <h2 className="fw-700 font-bukra text-black">
                  {t("Governing Law")}
                </h2>
                <p>
                  {t(
                    "This Agreement is entered into in the Emirate of United Arab Emirates and shall be governed by and constructed in accordance with the laws of the United Arab Emirates."
                  )}
                </p>
              </div>
              <div className="textBlock">
                <h2 className="fw-700 font-bukra text-black">
                  {t("Modifications")}
                </h2>
                <p>
                  {t(
                    "ESAAD may amend or modify this agreement or impose new conditions at any time. Any use of the Web site by you after such notice shall be deemed to constitute acceptance by you of such amendments, modifications or new conditions. You are responsible for regularly reviewing these terms, conditions and notices."
                  )}
                </p>
              </div>
              <div className="textBlock">
                <h2 className="fw-700 font-bukra text-black">
                  {t("Settlement of Disputes Relating Electronic Payment")}
                </h2>
                <p>
                  {t(
                    'To make sure you get "ESAAD" services that require electronic payment, legally and safely, avoid payment through an intermediary or by using a credit card or direct debit and/or using other means of payment that belong to third parties, without their consent.'
                  )}
                </p>
                <p>
                  {t(
                    "In case of violation of the above and/or complaint from the rightful owner of the means of electronic payment used, the client (whether natural or legal person) is subject to legal accountability and is punished by imprisonment and payment of fine, or by either, in accordance with Articles 11, 12, 13 and 14 of the Decree of Federal Law (5/2012), related to combating cybercrimes, without prejudice to the right of ESAAD in exercising all means of legal protection and rights available and/or granted to it under the law."
                  )}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default TermsAndCondition;
