import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Offcanvas,
  Row,
  Modal,
} from "react-bootstrap";
import InputField from "../Form/InputField";
import { useTranslation } from "react-i18next";
import Image from "react-bootstrap/Image";
import { useMutation, useQuery } from "@tanstack/react-query";
import { HomeAPI } from "../../apis/homeApi";
import { useSelector } from "react-redux";
import { selectLangState } from "../../redux/configurationSlice";
import { Controller } from "react-hook-form";
import { toast } from "react-toastify";
import FieldValidationText from "../Global/fieldValidationText";
import ButtonWithLoading from "../buttons/ButtonWithLoading";
import useForm from "../../hooks/useForm";
import InputLabel from "../Form/InputLabel";
import PhoneNumberField from "../Global/phoneInput";
import { phoneNumberForDB } from "../../utils/utilityFunctions";

const FEELING_SVGS = {
  SAD: (
    <svg
      width="172"
      height="171"
      viewBox="0 0 172 171"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M85.9553 170.819C69.0645 170.817 52.5536 165.806 38.5104 156.42C24.4673 147.033 13.5226 133.694 7.0603 118.087C0.597999 102.48 -1.09168 85.3073 2.20493 68.7402C5.50153 52.1731 13.6364 36.9556 25.5808 25.012C37.5252 13.0683 52.7427 4.93497 69.3092 1.64034C85.8756 -1.6543 103.047 0.0377535 118.652 6.50254C134.257 12.9673 147.594 23.9145 156.978 37.9598C166.362 52.0051 171.371 68.5177 171.371 85.4097C171.344 108.055 162.336 129.766 146.323 145.778C130.31 161.79 108.6 170.795 85.9553 170.819ZM85.9553 16.8995C72.4066 16.9017 59.1628 20.9216 47.8986 28.451C36.6344 35.9803 27.8556 46.681 22.6723 59.1998C17.489 71.7186 16.134 85.4933 18.7785 98.7823C21.4231 112.071 27.9485 124.278 37.5297 133.858C47.1108 143.438 59.3174 149.962 72.6059 152.604C85.8944 155.247 99.668 153.89 112.185 148.704C124.702 143.518 135.401 134.737 142.928 123.471C150.455 112.205 154.472 98.9593 154.472 85.4097C154.451 67.2441 147.226 49.8287 134.38 36.9847C121.535 24.1408 104.12 16.9173 85.9553 16.8995Z"
        fill="#008755"
      />
      <path
        className="line"
        d="M138.586 119.397C134.628 108.989 127.672 99.9895 118.598 93.5355C109.525 87.0815 98.7411 83.463 87.611 83.1376C76.481 82.8123 65.5044 85.7947 56.0694 91.7077C46.6343 97.6207 39.1646 106.199 34.6047 116.357L48.1675 122.445C51.5233 114.969 57.0205 108.656 63.9641 104.305C70.9077 99.9532 78.9857 97.7584 87.1766 97.9978C95.3676 98.2373 103.304 100.9 109.981 105.65C116.659 110.4 121.778 117.023 124.691 124.682L138.586 119.397Z"
        fill="#008755"
      />
    </svg>
  ),
  NEUTRAL: (
    <svg
      width="172"
      height="171"
      viewBox="0 0 172 171"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M85.9865 170.819C69.0957 170.817 52.5848 165.806 38.5417 156.42C24.4985 147.033 13.5539 133.694 7.09155 118.087C0.629249 102.48 -1.06043 85.3073 2.23618 68.7402C5.53278 52.1731 13.6676 36.9556 25.612 25.012C37.5564 13.0683 52.774 4.93497 69.3404 1.64034C85.9069 -1.6543 103.078 0.0377535 118.683 6.50254C134.288 12.9673 147.626 23.9145 157.009 37.9598C166.393 52.0051 171.402 68.5177 171.402 85.4097C171.375 108.055 162.367 129.766 146.354 145.778C130.341 161.79 108.631 170.795 85.9865 170.819ZM85.9865 16.8995C72.4379 16.9017 59.1941 20.9216 47.9299 28.451C36.6656 35.9803 27.8869 46.681 22.7035 59.1998C17.5202 71.7186 16.1652 85.4933 18.8098 98.7823C21.4544 112.071 27.9798 124.278 37.5609 133.858C47.1421 143.438 59.3486 149.962 72.6371 152.604C85.9257 155.247 99.6993 153.89 112.216 148.704C124.734 143.518 135.432 134.737 142.959 123.471C150.486 112.205 154.504 98.9593 154.504 85.4097C154.483 67.2441 147.257 49.8287 134.412 36.9847C121.566 24.1408 104.151 16.9173 85.9865 16.8995Z"
        fill="#008755"
      />
      <path
        className="line"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M130.939 118.108H43.3125V101.251H130.939V118.108Z"
        fill="#008755"
      />
    </svg>
  ),
  HAPPY: (
    <svg
      width="172"
      height="171"
      viewBox="0 0 172 171"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M86.0178 170.819C69.127 170.817 52.6161 165.806 38.5729 156.42C24.5298 147.033 13.5851 133.694 7.1228 118.087C0.660499 102.48 -1.02918 85.3073 2.26743 68.7402C5.56403 52.1731 13.6989 36.9556 25.6433 25.012C37.5877 13.0683 52.8052 4.93497 69.3717 1.64034C85.9381 -1.6543 103.109 0.0377535 118.714 6.50254C134.319 12.9673 147.657 23.9145 157.041 37.9598C166.424 52.0051 171.433 68.5177 171.433 85.4097C171.406 108.055 162.398 129.766 146.385 145.778C130.372 161.79 108.662 170.795 86.0178 170.819ZM86.0178 16.8995C72.4691 16.9017 59.2253 20.9216 47.9611 28.451C36.6969 35.9803 27.9181 46.681 22.7348 59.1998C17.5515 71.7186 16.1965 85.4933 18.841 98.7823C21.4856 112.071 28.011 124.278 37.5922 133.858C47.1733 143.438 59.3799 149.962 72.6684 152.604C85.9569 155.247 99.7305 153.89 112.248 148.704C124.765 143.518 135.463 134.737 142.99 123.471C150.517 112.205 154.535 98.9593 154.535 85.4097C154.514 67.2441 147.288 49.8287 134.443 36.9847C121.598 24.1408 104.182 16.9173 86.0178 16.8995Z"
        fill="#008755"
      />
      <path
        className="line"
        d="M138.649 100.944C134.69 111.352 127.734 120.352 118.661 126.806C109.587 133.26 98.8036 136.878 87.6735 137.203C76.5435 137.529 65.5669 134.546 56.1319 128.633C46.6968 122.72 39.2271 114.142 34.6672 103.984L48.23 97.896C51.5858 105.372 57.083 111.685 64.0266 116.036C70.9702 120.388 79.0482 122.583 87.2391 122.343C95.4301 122.104 103.366 119.441 110.044 114.691C116.721 109.941 121.84 103.318 124.754 95.6591L138.649 100.944Z"
        fill="#008755"
      />
    </svg>
  ),
};

const HappinessMetter = ({ happinessShow, setHappinessShow }) => {
  const { t } = useTranslation();
  const [successMessage, setSuccessMessage] = useState("");
  const { lang } = useSelector(selectLangState);
  const handleCloseMeter = () => {
    setHappinessShow(false);
    reset({
      comment: "",
      mobile_number: "",
      questions: [],
      feeling: null,
    });
    setTab(1);
  };
  const [tab, setTab] = useState(1);
  const {
    handleSubmit,
    handleChange,
    control,
    reset,
    watch,
    register,
    setValue,
    trigger,
    formState: { errors, isDirty, isSubmitting, isValid },
  } = useForm({
    mode: "all",
  });

  const { data } = useQuery({
    queryKey: [
      "getfeedback",
      {
        company: "ESD",
        location_id: 0,
        language: lang?.startsWith("ar") ? "ar" : "en",
        platform: "website",
      },
    ],
    queryFn: ({ queryKey }) => HomeAPI.getFeedback({ ...queryKey[1] }),
  });

  const isMobile = window.innerWidth <= 767;
  useEffect(() => {
    setTab(1);
    reset();
  }, [happinessShow]);
  useEffect(() => {
    const newQuestions = data?.questions?.map((question) => {
      return {
        ...question,
        smiley: null,
      };
    });
    setValue("questions", newQuestions);
  }, [data, happinessShow]);

  const resetQuestions = () => {
    data?.questions?.forEach((question, key) => {
      setValue(`questions.${key}.smiley`, null);
    });
  };

  const onSuccess = (response) => {
    isMobile && toast.success(response?.success_section?.desc);
    reset({
      questions: [],
    });
    setSuccessMessage(response?.success_section);
    isMobile ? setTab(1) : setTab(4);
    isMobile && setHappinessShow(false);
  };
  const onError = (error) => {
    toast.error(error?.message);
    !isMobile && setTab(5);
  };
  const {
    mutateAsync: submitFeedback,
    status,
    error,
  } = useMutation({
    mutationFn: (data) => HomeAPI.submitFeedback(data),
    onSuccess,
    onError,
  });

  const onSubmit = async (values) => {
    const payload = {
      comment: values?.comment,
      questions: values?.questions?.map((question) => {
        return {
          id: question?.id,
          smiley: question?.smiley,
        };
      }),
    };

    if (values?.feeling) {
      payload.questions = [
        ...payload.questions,
        { id: data?.main_question?.id, smiley: values?.feeling },
      ];
    }
    if (values?.mobile_number) {
      payload.mobile_number = phoneNumberForDB(values?.mobile_number);
    }
    try {
      await submitFeedback({
        ...payload,
        company: "ESD",
        location_id: 0,
        language: lang?.startsWith("ar") ? "ar" : "en",
        platform: "website",
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {!isMobile ? (
        <Modal
          size="lg"
          centered
          show={happinessShow}
          onHide={handleCloseMeter}
          className="happiness_Modal"
          backdropClassName="happinessMeter"
        >
          <Modal.Body>
            <Button
              className="closeBtn primary-color text-decoration-none fw-700"
              variant="link"
              onClick={handleCloseMeter}
            >
              {t("Close")}
            </Button>
            {data?.questions?.length == 0 ? (
              <div className="text-center p-5">{t("Data not found")}</div>
            ) : (
              <>
                <div
                  className={`sectionsWrap ${
                    tab === 1
                      ? "firstTab"
                      : tab === 2
                      ? "secondTab"
                      : tab === 3
                      ? "thirdTab"
                      : tab === 4 || tab === 5
                      ? "fourthTab"
                      : ""
                  }`}
                >
                  <div className="sectionItem section_One">
                    <div className="head d-flex align-items-center justify-content-center">
                      <div className="icon me-2">
                        <svg
                          width="50"
                          height="50"
                          viewBox="0 0 50 50"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M24.9951 50C20.051 49.9993 15.2181 48.5326 11.1076 45.7851C6.9971 43.0377 3.79351 39.133 1.90195 34.5648C0.0103908 29.9966 -0.484189 24.97 0.48075 20.1207C1.44569 15.2714 3.82681 10.8172 7.32302 7.32118C10.8192 3.82519 15.2735 1.4445 20.1226 0.480138C24.9717 -0.484225 29.9979 0.0110507 34.5656 1.90334C39.1332 3.79563 43.0372 6.99994 45.7839 11.1111C48.5307 15.2223 49.9967 20.0556 49.9967 25C49.9888 31.6286 47.3521 37.9834 42.665 42.6702C37.9779 47.357 31.6232 49.993 24.9951 50ZM24.9951 4.94659C21.0293 4.94724 17.1527 6.12391 13.8556 8.3278C10.5585 10.5317 7.98888 13.6638 6.47169 17.3282C4.9545 20.9925 4.55787 25.0245 5.33196 28.9143C6.10605 32.804 8.01608 36.3769 10.8205 39.1811C13.625 41.9853 17.198 43.8948 21.0876 44.6683C24.9772 45.4418 29.0089 45.0445 32.6727 43.5266C36.3366 42.0087 39.4681 39.4384 41.6713 36.1407C43.8745 32.843 45.0505 28.9661 45.0505 25C45.0443 19.6828 42.9293 14.5852 39.1694 10.8257C35.4095 7.06617 30.3119 4.95182 24.9951 4.94659Z"
                            fill="#008755"
                          />
                          <path
                            d="M40.785 29.7308C39.6191 32.796 37.5704 35.4467 34.898 37.3476C32.2256 39.2484 29.0495 40.3142 25.7714 40.41C22.4933 40.5058 19.2604 39.6274 16.4816 37.8859C13.7027 36.1444 11.5027 33.6179 10.1597 30.626L14.1543 28.8329C15.1426 31.0348 16.7617 32.8941 18.8068 34.1757C20.8518 35.4574 23.231 36.1038 25.6435 36.0333C28.0559 35.9628 30.3933 35.1784 32.36 33.7795C34.3267 32.3806 35.8344 30.4299 36.6925 28.1741L40.785 29.7308Z"
                            fill="#008755"
                          />
                        </svg>
                      </div>
                      <div
                        className="title_img"
                        style={{ marginBottom: "-10px" }}
                      >
                        <Image
                          src="/images/happiness-meter-title.svg"
                          alt="Happiness Meter Title"
                          className="d-block img-fluid w-100"
                        />
                      </div>
                    </div>
                    <div className="hp_expIcons d-flex justify-content-around mb-5">
                      <Controller
                        control={control}
                        name="feeling"
                        render={({ field }) => (
                          <span
                            className={`icon cursor-pointer rounded-circle bounce-in   ${
                              field.value === "SAD" && "selected"
                            }`}
                            onClick={() => {
                              field.onChange("SAD");
                              if (field.value !== "SAD") {
                                resetQuestions();
                              }
                              setTab(2);
                            }}
                          >
                            <svg
                              width="172"
                              height="171"
                              viewBox="0 0 172 171"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M85.9553 170.819C69.0645 170.817 52.5536 165.806 38.5104 156.42C24.4673 147.033 13.5226 133.694 7.0603 118.087C0.597999 102.48 -1.09168 85.3073 2.20493 68.7402C5.50153 52.1731 13.6364 36.9556 25.5808 25.012C37.5252 13.0683 52.7427 4.93497 69.3092 1.64034C85.8756 -1.6543 103.047 0.0377535 118.652 6.50254C134.257 12.9673 147.594 23.9145 156.978 37.9598C166.362 52.0051 171.371 68.5177 171.371 85.4097C171.344 108.055 162.336 129.766 146.323 145.778C130.31 161.79 108.6 170.795 85.9553 170.819ZM85.9553 16.8995C72.4066 16.9017 59.1628 20.9216 47.8986 28.451C36.6344 35.9803 27.8556 46.681 22.6723 59.1998C17.489 71.7186 16.134 85.4933 18.7785 98.7823C21.4231 112.071 27.9485 124.278 37.5297 133.858C47.1108 143.438 59.3174 149.962 72.6059 152.604C85.8944 155.247 99.668 153.89 112.185 148.704C124.702 143.518 135.401 134.737 142.928 123.471C150.455 112.205 154.472 98.9593 154.472 85.4097C154.451 67.2441 147.226 49.8287 134.38 36.9847C121.535 24.1408 104.12 16.9173 85.9553 16.8995Z"
                                fill="#008755"
                              />
                              <path
                                className="line"
                                d="M138.586 119.397C134.628 108.989 127.672 99.9895 118.598 93.5355C109.525 87.0815 98.7411 83.463 87.611 83.1376C76.481 82.8123 65.5044 85.7947 56.0694 91.7077C46.6343 97.6207 39.1646 106.199 34.6047 116.357L48.1675 122.445C51.5233 114.969 57.0205 108.656 63.9641 104.305C70.9077 99.9532 78.9857 97.7584 87.1766 97.9978C95.3676 98.2373 103.304 100.9 109.981 105.65C116.659 110.4 121.778 117.023 124.691 124.682L138.586 119.397Z"
                                fill="#008755"
                              />
                            </svg>
                          </span>
                        )}
                      />
                      <Controller
                        control={control}
                        name="feeling"
                        render={({ field }) => (
                          <span
                            onClick={() => {
                              field.onChange("NEUTRAL");
                              if (field.value !== "NEUTRAL") {
                                resetQuestions();
                              }
                              setTab(2);
                            }}
                            className={`icon cursor-pointer rounded-circle bounce-in  ${
                              field.value === "NEUTRAL" && "selected"
                            }`}
                          >
                            <svg
                              width="172"
                              height="171"
                              viewBox="0 0 172 171"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M85.9865 170.819C69.0957 170.817 52.5848 165.806 38.5417 156.42C24.4985 147.033 13.5539 133.694 7.09155 118.087C0.629249 102.48 -1.06043 85.3073 2.23618 68.7402C5.53278 52.1731 13.6676 36.9556 25.612 25.012C37.5564 13.0683 52.774 4.93497 69.3404 1.64034C85.9069 -1.6543 103.078 0.0377535 118.683 6.50254C134.288 12.9673 147.626 23.9145 157.009 37.9598C166.393 52.0051 171.402 68.5177 171.402 85.4097C171.375 108.055 162.367 129.766 146.354 145.778C130.341 161.79 108.631 170.795 85.9865 170.819ZM85.9865 16.8995C72.4379 16.9017 59.1941 20.9216 47.9299 28.451C36.6656 35.9803 27.8869 46.681 22.7035 59.1998C17.5202 71.7186 16.1652 85.4933 18.8098 98.7823C21.4544 112.071 27.9798 124.278 37.5609 133.858C47.1421 143.438 59.3486 149.962 72.6371 152.604C85.9257 155.247 99.6993 153.89 112.216 148.704C124.734 143.518 135.432 134.737 142.959 123.471C150.486 112.205 154.504 98.9593 154.504 85.4097C154.483 67.2441 147.257 49.8287 134.412 36.9847C121.566 24.1408 104.151 16.9173 85.9865 16.8995Z"
                                fill="#008755"
                              />
                              <path
                                className="line"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M130.939 118.108H43.3125V101.251H130.939V118.108Z"
                                fill="#008755"
                              />
                            </svg>
                          </span>
                        )}
                      />
                      <Controller
                        control={control}
                        name="feeling"
                        render={({ field }) => (
                          <span
                            onClick={() => {
                              field.onChange("HAPPY");
                              if (field.value !== "HAPPY") {
                                resetQuestions();
                              }
                              setTab(2);
                            }}
                            className={`icon cursor-pointer rounded-circle bounce-in ${
                              field.value === "HAPPY" && "selected"
                            }`}
                          >
                            <svg
                              width="172"
                              height="171"
                              viewBox="0 0 172 171"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M86.0178 170.819C69.127 170.817 52.6161 165.806 38.5729 156.42C24.5298 147.033 13.5851 133.694 7.1228 118.087C0.660499 102.48 -1.02918 85.3073 2.26743 68.7402C5.56403 52.1731 13.6989 36.9556 25.6433 25.012C37.5877 13.0683 52.8052 4.93497 69.3717 1.64034C85.9381 -1.6543 103.109 0.0377535 118.714 6.50254C134.319 12.9673 147.657 23.9145 157.041 37.9598C166.424 52.0051 171.433 68.5177 171.433 85.4097C171.406 108.055 162.398 129.766 146.385 145.778C130.372 161.79 108.662 170.795 86.0178 170.819ZM86.0178 16.8995C72.4691 16.9017 59.2253 20.9216 47.9611 28.451C36.6969 35.9803 27.9181 46.681 22.7348 59.1998C17.5515 71.7186 16.1965 85.4933 18.841 98.7823C21.4856 112.071 28.011 124.278 37.5922 133.858C47.1733 143.438 59.3799 149.962 72.6684 152.604C85.9569 155.247 99.7305 153.89 112.248 148.704C124.765 143.518 135.463 134.737 142.99 123.471C150.517 112.205 154.535 98.9593 154.535 85.4097C154.514 67.2441 147.288 49.8287 134.443 36.9847C121.598 24.1408 104.182 16.9173 86.0178 16.8995Z"
                                fill="#008755"
                              />
                              <path
                                className="line"
                                d="M138.649 100.944C134.69 111.352 127.734 120.352 118.661 126.806C109.587 133.26 98.8036 136.878 87.6735 137.203C76.5435 137.529 65.5669 134.546 56.1319 128.633C46.6968 122.72 39.2271 114.142 34.6672 103.984L48.23 97.896C51.5858 105.372 57.083 111.685 64.0266 116.036C70.9702 120.388 79.0482 122.583 87.2391 122.343C95.4301 122.104 103.366 119.441 110.044 114.691C116.721 109.941 121.84 103.318 124.754 95.6591L138.649 100.944Z"
                                fill="#008755"
                              />
                            </svg>
                          </span>
                        )}
                      />
                    </div>
                    <h2 className="fw-700 font-bukra text-black title-lg text-center">
                      {data?.main_question?.text}
                    </h2>
                  </div>
                  <div className="sectionItem section_Two">
                    <div className="head d-flex align-items-center justify-content-center">
                      <div className="icon me-2">
                        {FEELING_SVGS[watch("feeling")]}
                      </div>
                      <h4 className="fw-700 font-bukra text-black m-0 pt-1">
                        {data?.question_titles?.[watch("feeling")]}
                      </h4>
                    </div>
                    <div className="my-5">
                      <Controller
                        control={control}
                        name="questions"
                        render={({ field }) => (
                          <ul className="list-unstyled hp_expIcons hpExp_list">
                            {field?.value?.map((item, key) => (
                              <Fragment key={key}>
                                <Controller
                                  control={control}
                                  name={`questions.${key}.smiley`}
                                  rules={{ required: t("Choose an option") }}
                                  render={({ field }) => (
                                    <li className="d-flex align-items-center justify-content-between">
                                      <span>{item?.text}</span>
                                      <div className="d-flex">
                                        <span
                                          className={`icon cursor-pointer rounded-circle ${
                                            field?.value === "SAD" && "selected"
                                          }`}
                                          onClick={() => {
                                            field.onChange("SAD");
                                          }}
                                        >
                                          <svg
                                            width="50"
                                            height="50"
                                            viewBox="0 0 50 50"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M24.9967 50C20.0523 49.9993 15.2191 48.5326 11.1084 45.7851C6.99756 43.0377 3.79376 39.133 1.90208 34.5648C0.0103915 29.9966 -0.484221 24.97 0.480781 20.1207C1.44578 15.2714 3.82706 10.8172 7.32351 7.32118C10.8199 3.82519 15.2745 1.4445 20.124 0.480138C24.9734 -0.484225 29.9999 0.0110507 34.5679 1.90334C39.1358 3.79563 43.0401 6.99994 45.787 11.1111C48.5339 15.2223 50 20.0556 50 25C49.9921 31.6286 47.3553 37.9834 42.6678 42.6702C37.9804 47.357 31.6253 49.993 24.9967 50ZM24.9967 4.94659C21.0307 4.94724 17.1539 6.12391 13.8565 8.3278C10.5592 10.5317 7.98941 13.6638 6.47212 17.3282C4.95483 20.9925 4.55817 25.0245 5.33231 28.9143C6.10645 32.804 8.01661 36.3769 10.8213 39.1811C13.6259 41.9853 17.1991 43.8948 21.089 44.6683C24.9789 45.4418 29.0108 45.0445 32.6749 43.5266C36.339 42.0087 39.4707 39.4384 41.674 36.1407C43.8774 32.843 45.0534 28.9661 45.0534 25C45.0473 19.6828 42.9321 14.5852 39.172 10.8257C35.4119 7.06617 30.3139 4.95182 24.9967 4.94659Z"
                                              fill="#008755"
                                            />
                                            <path
                                              className="line"
                                              d="M40.4045 34.9483C39.2457 31.902 37.2096 29.2677 34.5535 27.3786C31.8974 25.4895 28.7408 24.4303 25.4827 24.3351C22.2247 24.2398 19.0115 25.1128 16.2497 26.8436C13.4878 28.5744 11.3012 31.0852 9.96639 34.0586L13.9366 35.8406C14.9189 33.6524 16.5281 31.8046 18.5606 30.5309C20.5932 29.2571 22.9579 28.6147 25.3556 28.6848C27.7533 28.7548 30.0764 29.5343 32.031 30.9246C33.9857 32.3149 35.4842 34.2535 36.337 36.4954L40.4045 34.9483Z"
                                              fill="#008755"
                                            />
                                          </svg>
                                        </span>
                                        <span
                                          className={`icon cursor-pointer rounded-circle ms-2 ${
                                            field?.value === "NEUTRAL" &&
                                            "selected"
                                          }`}
                                          onClick={() => {
                                            field.onChange("NEUTRAL");
                                          }}
                                        >
                                          <svg
                                            width="50"
                                            height="50"
                                            viewBox="0 0 50 50"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M24.9967 50C20.0523 49.9993 15.2191 48.5326 11.1084 45.7851C6.99756 43.0377 3.79376 39.133 1.90208 34.5648C0.0103915 29.9966 -0.484221 24.97 0.480781 20.1207C1.44578 15.2714 3.82706 10.8172 7.32351 7.32118C10.8199 3.82519 15.2745 1.4445 20.124 0.480138C24.9734 -0.484225 29.9999 0.0110507 34.5679 1.90334C39.1358 3.79563 43.0401 6.99994 45.787 11.1111C48.5339 15.2223 50 20.0556 50 25C49.9921 31.6286 47.3553 37.9834 42.6678 42.6702C37.9804 47.357 31.6253 49.993 24.9967 50ZM24.9967 4.94659C21.0307 4.94724 17.1539 6.12391 13.8565 8.3278C10.5592 10.5317 7.98941 13.6638 6.47212 17.3282C4.95483 20.9925 4.55817 25.0245 5.33231 28.9143C6.10645 32.804 8.01661 36.3769 10.8213 39.1811C13.6259 41.9853 17.1991 43.8948 21.089 44.6683C24.9789 45.4418 29.0108 45.0445 32.6749 43.5266C36.339 42.0087 39.4707 39.4384 41.674 36.1407C43.8774 32.843 45.0534 28.9661 45.0534 25C45.0473 19.6828 42.9321 14.5852 39.172 10.8257C35.4119 7.06617 30.3139 4.95182 24.9967 4.94659Z"
                                              fill="#008755"
                                            />
                                            <path
                                              className="line"
                                              fillRule="evenodd"
                                              clipRule="evenodd"
                                              d="M38.1506 34.5709H12.5V29.6367H38.1506V34.5709Z"
                                              fill="#008755"
                                            />
                                          </svg>
                                        </span>
                                        <span
                                          onClick={() => {
                                            field.onChange("HAPPY");
                                          }}
                                          className={`icon cursor-pointer rounded-circle ms-2 ${
                                            field?.value === "HAPPY" &&
                                            "selected"
                                          }`}
                                        >
                                          <svg
                                            width="50"
                                            height="50"
                                            viewBox="0 0 50 50"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M24.9951 50C20.051 49.9993 15.2181 48.5326 11.1076 45.7851C6.9971 43.0377 3.79351 39.133 1.90195 34.5648C0.0103908 29.9966 -0.484189 24.97 0.48075 20.1207C1.44569 15.2714 3.82681 10.8172 7.32302 7.32118C10.8192 3.82519 15.2735 1.4445 20.1226 0.480138C24.9717 -0.484225 29.9979 0.0110507 34.5656 1.90334C39.1332 3.79563 43.0372 6.99994 45.7839 11.1111C48.5307 15.2223 49.9967 20.0556 49.9967 25C49.9888 31.6286 47.3521 37.9834 42.665 42.6702C37.9779 47.357 31.6232 49.993 24.9951 50ZM24.9951 4.94659C21.0293 4.94724 17.1527 6.12391 13.8556 8.3278C10.5585 10.5317 7.98888 13.6638 6.47169 17.3282C4.9545 20.9925 4.55787 25.0245 5.33196 28.9143C6.10605 32.804 8.01608 36.3769 10.8205 39.1811C13.625 41.9853 17.198 43.8948 21.0876 44.6683C24.9772 45.4418 29.0089 45.0445 32.6727 43.5266C36.3366 42.0087 39.4681 39.4384 41.6713 36.1407C43.8745 32.843 45.0505 28.9661 45.0505 25C45.0443 19.6828 42.9293 14.5852 39.1694 10.8257C35.4095 7.06617 30.3119 4.95182 24.9951 4.94659Z"
                                              fill="#008755"
                                            />
                                            <path
                                              className="line"
                                              d="M40.785 29.7308C39.6191 32.796 37.5704 35.4467 34.898 37.3476C32.2256 39.2484 29.0495 40.3142 25.7714 40.41C22.4933 40.5058 19.2604 39.6274 16.4816 37.8859C13.7027 36.1444 11.5027 33.6179 10.1597 30.626L14.1543 28.8329C15.1426 31.0348 16.7617 32.8941 18.8068 34.1757C20.8518 35.4574 23.231 36.1038 25.6435 36.0333C28.0559 35.9628 30.3933 35.1784 32.36 33.7795C34.3267 32.3806 35.8344 30.4299 36.6925 28.1741L40.785 29.7308Z"
                                              fill="#008755"
                                            />
                                          </svg>
                                        </span>
                                      </div>
                                    </li>
                                  )}
                                />
                                {errors?.questions?.[key]?.smiley && (
                                  <FieldValidationText
                                    errorMessage={
                                      errors?.questions?.[key]?.smiley?.message
                                    }
                                  />
                                )}
                              </Fragment>
                            ))}
                          </ul>
                        )}
                      />
                    </div>
                    <div className="ht_stepsBtn d-flex justify-content-between">
                      <Button
                        onClick={() => setTab(1)}
                        variant="outline"
                        className="px-5"
                      >
                        {t("Back")}
                      </Button>
                      <Button
                        onClick={async (e) => {
                          e.preventDefault();
                          const isValid = await trigger([
                            "feeling",
                            "questions",
                          ]);

                          if (isValid) {
                            setTab(3);
                          }
                        }}
                        variant="primary"
                        className="px-5"
                      >
                        {data?.question_btn_txt}
                      </Button>
                    </div>
                  </div>

                  <div className="sectionItem section_Three">
                    <div className="head d-flex align-items-center justify-content-center">
                      <div className="icon me-2">
                        {FEELING_SVGS[watch("feeling")]}
                      </div>
                      <h4 className="fw-700 font-bukra text-black m-0 pt-1">
                        {t("Tell us More")}
                      </h4>
                    </div>
                    <div className="my-5">
                      <Form.Group className="mb-3">
                        <InputLabel
                          text={data?.comment_title}
                          className="font-dubai"
                        />
                        <Controller
                          control={control}
                          name="comment"
                          render={({ field }) => (
                            <InputField
                              {...field}
                              className="font-dubai"
                              as="textarea"
                              rows={3}
                              placeholder={data?.comment_placeholder}
                              onChange={handleChange}
                            />
                          )}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <InputLabel
                          text={data?.mobile_title}
                          className="font-dubai"
                        />
                        <Controller
                          control={control}
                          name="mobile_number"
                          render={({ field }) => (
                            <PhoneNumberField
                              field={field}
                              trigger={trigger}
                              placeholder={data?.mobile_placeholder}
                            />
                          )}
                        />
                      </Form.Group>
                    </div>
                    <div className="ht_stepsBtn d-flex justify-content-between">
                      <Button
                        onClick={() => setTab(2)}
                        variant="outline"
                        className="px-5"
                      >
                        {t("Back")}
                      </Button>
                      <ButtonWithLoading
                        variant="primary"
                        type="submit"
                        className="fw-500 font-dubai d-block px-5 mb-3 mb-md-0"
                        text={data?.desc_btn_text}
                        disabled={!isValid || !isDirty || isSubmitting}
                        isLoading={status == "pending"}
                        onClick={handleSubmit(onSubmit)}
                      />
                    </div>
                  </div>

                  <div className="sectionItem section_Four">
                    {tab === 4 ? (
                      <>
                        <div className="head d-flex align-items-center justify-content-center mb-5">
                          <div className="icon me-2">
                            <svg
                              width="50"
                              height="50"
                              viewBox="0 0 50 50"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M24.9951 50C20.051 49.9993 15.2181 48.5326 11.1076 45.7851C6.9971 43.0377 3.79351 39.133 1.90195 34.5648C0.0103908 29.9966 -0.484189 24.97 0.48075 20.1207C1.44569 15.2714 3.82681 10.8172 7.32302 7.32118C10.8192 3.82519 15.2735 1.4445 20.1226 0.480138C24.9717 -0.484225 29.9979 0.0110507 34.5656 1.90334C39.1332 3.79563 43.0372 6.99994 45.7839 11.1111C48.5307 15.2223 49.9967 20.0556 49.9967 25C49.9888 31.6286 47.3521 37.9834 42.665 42.6702C37.9779 47.357 31.6232 49.993 24.9951 50ZM24.9951 4.94659C21.0293 4.94724 17.1527 6.12391 13.8556 8.3278C10.5585 10.5317 7.98888 13.6638 6.47169 17.3282C4.9545 20.9925 4.55787 25.0245 5.33196 28.9143C6.10605 32.804 8.01608 36.3769 10.8205 39.1811C13.625 41.9853 17.198 43.8948 21.0876 44.6683C24.9772 45.4418 29.0089 45.0445 32.6727 43.5266C36.3366 42.0087 39.4681 39.4384 41.6713 36.1407C43.8745 32.843 45.0505 28.9661 45.0505 25C45.0443 19.6828 42.9293 14.5852 39.1694 10.8257C35.4095 7.06617 30.3119 4.95182 24.9951 4.94659Z"
                                fill="#008755"
                              />
                              <path
                                d="M40.785 29.7308C39.6191 32.796 37.5704 35.4467 34.898 37.3476C32.2256 39.2484 29.0495 40.3142 25.7714 40.41C22.4933 40.5058 19.2604 39.6274 16.4816 37.8859C13.7027 36.1444 11.5027 33.6179 10.1597 30.626L14.1543 28.8329C15.1426 31.0348 16.7617 32.8941 18.8068 34.1757C20.8518 35.4574 23.231 36.1038 25.6435 36.0333C28.0559 35.9628 30.3933 35.1784 32.36 33.7795C34.3267 32.3806 35.8344 30.4299 36.6925 28.1741L40.785 29.7308Z"
                                fill="#008755"
                              />
                            </svg>
                          </div>
                          <div
                            className="title_img"
                            style={{ marginBottom: "-10px" }}
                          >
                            <Image
                              src="/images/happiness-meter-title.svg"
                              alt="Happiness Meter Title"
                              className="d-block img-fluid w-100"
                            />
                          </div>
                        </div>
                        <h2 className="title-lg fw-700 font-bukra primary-color py-5 m-0 px-5 text-center">
                          {successMessage?.desc}
                        </h2>
                      </>
                    ) : (
                      <>
                        <div className="head d-flex align-items-center justify-content-center mb-5">
                          <div className="icon me-2">
                            <svg
                              width="50"
                              height="50"
                              viewBox="0 0 50 50"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M24.9951 50C20.051 49.9993 15.2181 48.5326 11.1076 45.7851C6.9971 43.0377 3.79351 39.133 1.90195 34.5648C0.0103908 29.9966 -0.484189 24.97 0.48075 20.1207C1.44569 15.2714 3.82681 10.8172 7.32302 7.32118C10.8192 3.82519 15.2735 1.4445 20.1226 0.480138C24.9717 -0.484225 29.9979 0.0110507 34.5656 1.90334C39.1332 3.79563 43.0372 6.99994 45.7839 11.1111C48.5307 15.2223 49.9967 20.0556 49.9967 25C49.9888 31.6286 47.3521 37.9834 42.665 42.6702C37.9779 47.357 31.6232 49.993 24.9951 50ZM24.9951 4.94659C21.0293 4.94724 17.1527 6.12391 13.8556 8.3278C10.5585 10.5317 7.98888 13.6638 6.47169 17.3282C4.9545 20.9925 4.55787 25.0245 5.33196 28.9143C6.10605 32.804 8.01608 36.3769 10.8205 39.1811C13.625 41.9853 17.198 43.8948 21.0876 44.6683C24.9772 45.4418 29.0089 45.0445 32.6727 43.5266C36.3366 42.0087 39.4681 39.4384 41.6713 36.1407C43.8745 32.843 45.0505 28.9661 45.0505 25C45.0443 19.6828 42.9293 14.5852 39.1694 10.8257C35.4095 7.06617 30.3119 4.95182 24.9951 4.94659Z"
                                fill="#008755"
                              />
                              <path
                                d="M40.785 29.7308C39.6191 32.796 37.5704 35.4467 34.898 37.3476C32.2256 39.2484 29.0495 40.3142 25.7714 40.41C22.4933 40.5058 19.2604 39.6274 16.4816 37.8859C13.7027 36.1444 11.5027 33.6179 10.1597 30.626L14.1543 28.8329C15.1426 31.0348 16.7617 32.8941 18.8068 34.1757C20.8518 35.4574 23.231 36.1038 25.6435 36.0333C28.0559 35.9628 30.3933 35.1784 32.36 33.7795C34.3267 32.3806 35.8344 30.4299 36.6925 28.1741L40.785 29.7308Z"
                                fill="#008755"
                              />
                            </svg>
                          </div>
                          <div
                            className="title_img"
                            style={{ marginBottom: "-10px" }}
                          >
                            <Image
                              src="/images/happiness-meter-title.svg"
                              alt="Happiness Meter Title"
                              className="d-block img-fluid w-100"
                            />
                          </div>
                        </div>
                        <div className="errorImg mx-auto mb-4">
                          <Image
                            src="/images/polygone.svg"
                            alt="error image"
                            className="d-block img-fluid w-100"
                          />
                        </div>
                        <h2 className="title-lg fw-700 font-bukra mb-5 px-5 text-center">
                          {t("Something went wrong.")}
                        </h2>
                        <div className="ht_stepsBtn d-flex justify-content-between">
                          <Button
                            onClick={() => setTab(3)}
                            variant="outline"
                            className="px-5"
                          >
                            {t("Back")}
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </Modal.Body>
        </Modal>
      ) : (
        <Offcanvas
          show={happinessShow}
          onHide={handleCloseMeter}
          className="happiness_Modal w-100"
        >
          <Offcanvas.Header closeButton>
            <div className="head d-flex align-items-center justify-content-center w-100 m-0">
              <div className="icon me-2">
                <svg
                  width="50"
                  height="50"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24.9951 50C20.051 49.9993 15.2181 48.5326 11.1076 45.7851C6.9971 43.0377 3.79351 39.133 1.90195 34.5648C0.0103908 29.9966 -0.484189 24.97 0.48075 20.1207C1.44569 15.2714 3.82681 10.8172 7.32302 7.32118C10.8192 3.82519 15.2735 1.4445 20.1226 0.480138C24.9717 -0.484225 29.9979 0.0110507 34.5656 1.90334C39.1332 3.79563 43.0372 6.99994 45.7839 11.1111C48.5307 15.2223 49.9967 20.0556 49.9967 25C49.9888 31.6286 47.3521 37.9834 42.665 42.6702C37.9779 47.357 31.6232 49.993 24.9951 50ZM24.9951 4.94659C21.0293 4.94724 17.1527 6.12391 13.8556 8.3278C10.5585 10.5317 7.98888 13.6638 6.47169 17.3282C4.9545 20.9925 4.55787 25.0245 5.33196 28.9143C6.10605 32.804 8.01608 36.3769 10.8205 39.1811C13.625 41.9853 17.198 43.8948 21.0876 44.6683C24.9772 45.4418 29.0089 45.0445 32.6727 43.5266C36.3366 42.0087 39.4681 39.4384 41.6713 36.1407C43.8745 32.843 45.0505 28.9661 45.0505 25C45.0443 19.6828 42.9293 14.5852 39.1694 10.8257C35.4095 7.06617 30.3119 4.95182 24.9951 4.94659Z"
                    fill="#008755"
                  />
                  <path
                    d="M40.785 29.7308C39.6191 32.796 37.5704 35.4467 34.898 37.3476C32.2256 39.2484 29.0495 40.3142 25.7714 40.41C22.4933 40.5058 19.2604 39.6274 16.4816 37.8859C13.7027 36.1444 11.5027 33.6179 10.1597 30.626L14.1543 28.8329C15.1426 31.0348 16.7617 32.8941 18.8068 34.1757C20.8518 35.4574 23.231 36.1038 25.6435 36.0333C28.0559 35.9628 30.3933 35.1784 32.36 33.7795C34.3267 32.3806 35.8344 30.4299 36.6925 28.1741L40.785 29.7308Z"
                    fill="#008755"
                  />
                </svg>
              </div>
              <div className="title_img" style={{ marginBottom: "-10px" }}>
                <Image
                  src="/images/happiness-meter-title.svg"
                  alt="Happiness Meter Title"
                  className="d-block img-fluid w-100"
                />
              </div>
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {data?.questions?.length == 0 ? (
              <div className="text-center p-5">{t("Data not found")}</div>
            ) : (
              <>
                {(tab === 1 || tab === 2) && (
                  <>
                    <div className="hp_expIcons d-flex justify-content-around pb-5">
                      <Controller
                        control={control}
                        name="feeling"
                        render={({ field }) => (
                          <span
                            className={`icon cursor-pointer rounded-circle bounce-in   ${
                              field.value === "SAD" && "selected"
                            }`}
                            onClick={() => {
                              field.onChange("SAD");
                              if (field.value !== "SAD") {
                                resetQuestions();
                              }
                              setTab(2);
                            }}
                          >
                            <svg
                              width="172"
                              height="171"
                              viewBox="0 0 172 171"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M85.9553 170.819C69.0645 170.817 52.5536 165.806 38.5104 156.42C24.4673 147.033 13.5226 133.694 7.0603 118.087C0.597999 102.48 -1.09168 85.3073 2.20493 68.7402C5.50153 52.1731 13.6364 36.9556 25.5808 25.012C37.5252 13.0683 52.7427 4.93497 69.3092 1.64034C85.8756 -1.6543 103.047 0.0377535 118.652 6.50254C134.257 12.9673 147.594 23.9145 156.978 37.9598C166.362 52.0051 171.371 68.5177 171.371 85.4097C171.344 108.055 162.336 129.766 146.323 145.778C130.31 161.79 108.6 170.795 85.9553 170.819ZM85.9553 16.8995C72.4066 16.9017 59.1628 20.9216 47.8986 28.451C36.6344 35.9803 27.8556 46.681 22.6723 59.1998C17.489 71.7186 16.134 85.4933 18.7785 98.7823C21.4231 112.071 27.9485 124.278 37.5297 133.858C47.1108 143.438 59.3174 149.962 72.6059 152.604C85.8944 155.247 99.668 153.89 112.185 148.704C124.702 143.518 135.401 134.737 142.928 123.471C150.455 112.205 154.472 98.9593 154.472 85.4097C154.451 67.2441 147.226 49.8287 134.38 36.9847C121.535 24.1408 104.12 16.9173 85.9553 16.8995Z"
                                fill="#008755"
                              />
                              <path
                                className="line"
                                d="M138.586 119.397C134.628 108.989 127.672 99.9895 118.598 93.5355C109.525 87.0815 98.7411 83.463 87.611 83.1376C76.481 82.8123 65.5044 85.7947 56.0694 91.7077C46.6343 97.6207 39.1646 106.199 34.6047 116.357L48.1675 122.445C51.5233 114.969 57.0205 108.656 63.9641 104.305C70.9077 99.9532 78.9857 97.7584 87.1766 97.9978C95.3676 98.2373 103.304 100.9 109.981 105.65C116.659 110.4 121.778 117.023 124.691 124.682L138.586 119.397Z"
                                fill="#008755"
                              />
                            </svg>
                          </span>
                        )}
                      />
                      <Controller
                        control={control}
                        name="feeling"
                        render={({ field }) => (
                          <span
                            onClick={() => {
                              field.onChange("NEUTRAL");
                              if (field.value !== "NEUTRAL") {
                                resetQuestions();
                              }
                              setTab(2);
                            }}
                            className={`icon cursor-pointer rounded-circle bounce-in  ${
                              field.value === "NEUTRAL" && "selected"
                            }`}
                          >
                            <svg
                              width="172"
                              height="171"
                              viewBox="0 0 172 171"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M85.9865 170.819C69.0957 170.817 52.5848 165.806 38.5417 156.42C24.4985 147.033 13.5539 133.694 7.09155 118.087C0.629249 102.48 -1.06043 85.3073 2.23618 68.7402C5.53278 52.1731 13.6676 36.9556 25.612 25.012C37.5564 13.0683 52.774 4.93497 69.3404 1.64034C85.9069 -1.6543 103.078 0.0377535 118.683 6.50254C134.288 12.9673 147.626 23.9145 157.009 37.9598C166.393 52.0051 171.402 68.5177 171.402 85.4097C171.375 108.055 162.367 129.766 146.354 145.778C130.341 161.79 108.631 170.795 85.9865 170.819ZM85.9865 16.8995C72.4379 16.9017 59.1941 20.9216 47.9299 28.451C36.6656 35.9803 27.8869 46.681 22.7035 59.1998C17.5202 71.7186 16.1652 85.4933 18.8098 98.7823C21.4544 112.071 27.9798 124.278 37.5609 133.858C47.1421 143.438 59.3486 149.962 72.6371 152.604C85.9257 155.247 99.6993 153.89 112.216 148.704C124.734 143.518 135.432 134.737 142.959 123.471C150.486 112.205 154.504 98.9593 154.504 85.4097C154.483 67.2441 147.257 49.8287 134.412 36.9847C121.566 24.1408 104.151 16.9173 85.9865 16.8995Z"
                                fill="#008755"
                              />
                              <path
                                className="line"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M130.939 118.108H43.3125V101.251H130.939V118.108Z"
                                fill="#008755"
                              />
                            </svg>
                          </span>
                        )}
                      />
                      <Controller
                        control={control}
                        name="feeling"
                        render={({ field }) => (
                          <span
                            onClick={() => {
                              field.onChange("HAPPY");
                              if (field.value !== "HAPPY") {
                                resetQuestions();
                              }
                              setTab(2);
                            }}
                            className={`icon cursor-pointer rounded-circle bounce-in ${
                              field.value === "HAPPY" && "selected"
                            }`}
                          >
                            <svg
                              width="172"
                              height="171"
                              viewBox="0 0 172 171"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M86.0178 170.819C69.127 170.817 52.6161 165.806 38.5729 156.42C24.5298 147.033 13.5851 133.694 7.1228 118.087C0.660499 102.48 -1.02918 85.3073 2.26743 68.7402C5.56403 52.1731 13.6989 36.9556 25.6433 25.012C37.5877 13.0683 52.8052 4.93497 69.3717 1.64034C85.9381 -1.6543 103.109 0.0377535 118.714 6.50254C134.319 12.9673 147.657 23.9145 157.041 37.9598C166.424 52.0051 171.433 68.5177 171.433 85.4097C171.406 108.055 162.398 129.766 146.385 145.778C130.372 161.79 108.662 170.795 86.0178 170.819ZM86.0178 16.8995C72.4691 16.9017 59.2253 20.9216 47.9611 28.451C36.6969 35.9803 27.9181 46.681 22.7348 59.1998C17.5515 71.7186 16.1965 85.4933 18.841 98.7823C21.4856 112.071 28.011 124.278 37.5922 133.858C47.1733 143.438 59.3799 149.962 72.6684 152.604C85.9569 155.247 99.7305 153.89 112.248 148.704C124.765 143.518 135.463 134.737 142.99 123.471C150.517 112.205 154.535 98.9593 154.535 85.4097C154.514 67.2441 147.288 49.8287 134.443 36.9847C121.598 24.1408 104.182 16.9173 86.0178 16.8995Z"
                                fill="#008755"
                              />
                              <path
                                className="line"
                                d="M138.649 100.944C134.69 111.352 127.734 120.352 118.661 126.806C109.587 133.26 98.8036 136.878 87.6735 137.203C76.5435 137.529 65.5669 134.546 56.1319 128.633C46.6968 122.72 39.2271 114.142 34.6672 103.984L48.23 97.896C51.5858 105.372 57.083 111.685 64.0266 116.036C70.9702 120.388 79.0482 122.583 87.2391 122.343C95.4301 122.104 103.366 119.441 110.044 114.691C116.721 109.941 121.84 103.318 124.754 95.6591L138.649 100.944Z"
                                fill="#008755"
                              />
                            </svg>
                          </span>
                        )}
                      />
                    </div>
                    <hr />
                  </>
                )}
                {tab == 2 && watch("feeling") && (
                  <>
                    <h4 className="fw-700 font-bukra text-black m-0 pt-1 w-full text-center my-4">
                      {data?.question_titles?.[watch("feeling")]}
                    </h4>
                    <div className="section_one">
                      <Controller
                        control={control}
                        name="questions"
                        render={({ field }) => (
                          <ul className="list-unstyled hp_expIcons hpExp_list">
                            {field?.value?.map((item, key) => (
                              <Fragment key={key}>
                                <Controller
                                  control={control}
                                  name={`questions.${key}.smiley`}
                                  rules={{ required: t("Choose an option") }}
                                  render={({ field }) => (
                                    <li className="d-flex align-items-center justify-content-between">
                                      <span>{item?.text}</span>
                                      <div className="d-flex">
                                        <span
                                          className={`icon cursor-pointer rounded-circle ${
                                            field?.value === "SAD" && "selected"
                                          }`}
                                          onClick={() => {
                                            field.onChange("SAD");
                                          }}
                                        >
                                          <svg
                                            width="50"
                                            height="50"
                                            viewBox="0 0 50 50"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M24.9967 50C20.0523 49.9993 15.2191 48.5326 11.1084 45.7851C6.99756 43.0377 3.79376 39.133 1.90208 34.5648C0.0103915 29.9966 -0.484221 24.97 0.480781 20.1207C1.44578 15.2714 3.82706 10.8172 7.32351 7.32118C10.8199 3.82519 15.2745 1.4445 20.124 0.480138C24.9734 -0.484225 29.9999 0.0110507 34.5679 1.90334C39.1358 3.79563 43.0401 6.99994 45.787 11.1111C48.5339 15.2223 50 20.0556 50 25C49.9921 31.6286 47.3553 37.9834 42.6678 42.6702C37.9804 47.357 31.6253 49.993 24.9967 50ZM24.9967 4.94659C21.0307 4.94724 17.1539 6.12391 13.8565 8.3278C10.5592 10.5317 7.98941 13.6638 6.47212 17.3282C4.95483 20.9925 4.55817 25.0245 5.33231 28.9143C6.10645 32.804 8.01661 36.3769 10.8213 39.1811C13.6259 41.9853 17.1991 43.8948 21.089 44.6683C24.9789 45.4418 29.0108 45.0445 32.6749 43.5266C36.339 42.0087 39.4707 39.4384 41.674 36.1407C43.8774 32.843 45.0534 28.9661 45.0534 25C45.0473 19.6828 42.9321 14.5852 39.172 10.8257C35.4119 7.06617 30.3139 4.95182 24.9967 4.94659Z"
                                              fill="#008755"
                                            />
                                            <path
                                              className="line"
                                              d="M40.4045 34.9483C39.2457 31.902 37.2096 29.2677 34.5535 27.3786C31.8974 25.4895 28.7408 24.4303 25.4827 24.3351C22.2247 24.2398 19.0115 25.1128 16.2497 26.8436C13.4878 28.5744 11.3012 31.0852 9.96639 34.0586L13.9366 35.8406C14.9189 33.6524 16.5281 31.8046 18.5606 30.5309C20.5932 29.2571 22.9579 28.6147 25.3556 28.6848C27.7533 28.7548 30.0764 29.5343 32.031 30.9246C33.9857 32.3149 35.4842 34.2535 36.337 36.4954L40.4045 34.9483Z"
                                              fill="#008755"
                                            />
                                          </svg>
                                        </span>
                                        <span
                                          className={`icon cursor-pointer rounded-circle ms-2 ${
                                            field?.value === "NEUTRAL" &&
                                            "selected"
                                          }`}
                                          onClick={() => {
                                            field.onChange("NEUTRAL");
                                          }}
                                        >
                                          <svg
                                            width="50"
                                            height="50"
                                            viewBox="0 0 50 50"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M24.9967 50C20.0523 49.9993 15.2191 48.5326 11.1084 45.7851C6.99756 43.0377 3.79376 39.133 1.90208 34.5648C0.0103915 29.9966 -0.484221 24.97 0.480781 20.1207C1.44578 15.2714 3.82706 10.8172 7.32351 7.32118C10.8199 3.82519 15.2745 1.4445 20.124 0.480138C24.9734 -0.484225 29.9999 0.0110507 34.5679 1.90334C39.1358 3.79563 43.0401 6.99994 45.787 11.1111C48.5339 15.2223 50 20.0556 50 25C49.9921 31.6286 47.3553 37.9834 42.6678 42.6702C37.9804 47.357 31.6253 49.993 24.9967 50ZM24.9967 4.94659C21.0307 4.94724 17.1539 6.12391 13.8565 8.3278C10.5592 10.5317 7.98941 13.6638 6.47212 17.3282C4.95483 20.9925 4.55817 25.0245 5.33231 28.9143C6.10645 32.804 8.01661 36.3769 10.8213 39.1811C13.6259 41.9853 17.1991 43.8948 21.089 44.6683C24.9789 45.4418 29.0108 45.0445 32.6749 43.5266C36.339 42.0087 39.4707 39.4384 41.674 36.1407C43.8774 32.843 45.0534 28.9661 45.0534 25C45.0473 19.6828 42.9321 14.5852 39.172 10.8257C35.4119 7.06617 30.3139 4.95182 24.9967 4.94659Z"
                                              fill="#008755"
                                            />
                                            <path
                                              className="line"
                                              fillRule="evenodd"
                                              clipRule="evenodd"
                                              d="M38.1506 34.5709H12.5V29.6367H38.1506V34.5709Z"
                                              fill="#008755"
                                            />
                                          </svg>
                                        </span>
                                        <span
                                          onClick={() => {
                                            field.onChange("HAPPY");
                                          }}
                                          className={`icon cursor-pointer rounded-circle ms-2 ${
                                            field?.value === "HAPPY" &&
                                            "selected"
                                          }`}
                                        >
                                          <svg
                                            width="50"
                                            height="50"
                                            viewBox="0 0 50 50"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M24.9951 50C20.051 49.9993 15.2181 48.5326 11.1076 45.7851C6.9971 43.0377 3.79351 39.133 1.90195 34.5648C0.0103908 29.9966 -0.484189 24.97 0.48075 20.1207C1.44569 15.2714 3.82681 10.8172 7.32302 7.32118C10.8192 3.82519 15.2735 1.4445 20.1226 0.480138C24.9717 -0.484225 29.9979 0.0110507 34.5656 1.90334C39.1332 3.79563 43.0372 6.99994 45.7839 11.1111C48.5307 15.2223 49.9967 20.0556 49.9967 25C49.9888 31.6286 47.3521 37.9834 42.665 42.6702C37.9779 47.357 31.6232 49.993 24.9951 50ZM24.9951 4.94659C21.0293 4.94724 17.1527 6.12391 13.8556 8.3278C10.5585 10.5317 7.98888 13.6638 6.47169 17.3282C4.9545 20.9925 4.55787 25.0245 5.33196 28.9143C6.10605 32.804 8.01608 36.3769 10.8205 39.1811C13.625 41.9853 17.198 43.8948 21.0876 44.6683C24.9772 45.4418 29.0089 45.0445 32.6727 43.5266C36.3366 42.0087 39.4681 39.4384 41.6713 36.1407C43.8745 32.843 45.0505 28.9661 45.0505 25C45.0443 19.6828 42.9293 14.5852 39.1694 10.8257C35.4095 7.06617 30.3119 4.95182 24.9951 4.94659Z"
                                              fill="#008755"
                                            />
                                            <path
                                              className="line"
                                              d="M40.785 29.7308C39.6191 32.796 37.5704 35.4467 34.898 37.3476C32.2256 39.2484 29.0495 40.3142 25.7714 40.41C22.4933 40.5058 19.2604 39.6274 16.4816 37.8859C13.7027 36.1444 11.5027 33.6179 10.1597 30.626L14.1543 28.8329C15.1426 31.0348 16.7617 32.8941 18.8068 34.1757C20.8518 35.4574 23.231 36.1038 25.6435 36.0333C28.0559 35.9628 30.3933 35.1784 32.36 33.7795C34.3267 32.3806 35.8344 30.4299 36.6925 28.1741L40.785 29.7308Z"
                                              fill="#008755"
                                            />
                                          </svg>
                                        </span>
                                      </div>
                                    </li>
                                  )}
                                />
                                {errors?.questions?.[key]?.smiley && (
                                  <FieldValidationText
                                    errorMessage={
                                      errors?.questions?.[key]?.smiley?.message
                                    }
                                  />
                                )}
                              </Fragment>
                            ))}
                          </ul>
                        )}
                      />
                    </div>
                  </>
                )}

                {tab === 3 && (
                  <div className="section_two">
                    <Form.Group className="mb-3">
                      <InputLabel
                        text={data?.comment_title}
                        className="font-dubai"
                      />
                      <Controller
                        name="comment"
                        control={control}
                        render={({ field }) => (
                          <InputField
                            {...field}
                            className="font-dubai"
                            as="textarea"
                            rows={3}
                            placeholder={data?.comment_placeholder}
                            onChange={handleChange}
                          />
                        )}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <InputLabel
                        text={data?.mobile_title}
                        className="font-dubai"
                      />
                      <Controller
                        name="mobile_number"
                        control={control}
                        render={({ field }) => (
                          <PhoneNumberField
                            field={field}
                            trigger={trigger}
                            placeholder={data?.mobile_placeholder}
                          />
                        )}
                      />
                    </Form.Group>
                  </div>
                )}

                <div className="ht_stepsBtn">
                  {tab == 1 && (
                    <Button
                      onClick={() =>
                        setTab((prev) => {
                          return prev + 1;
                        })
                      }
                      variant="primary"
                      className="w-100"
                    >
                      {data?.question_btn_txt}
                    </Button>
                  )}
                  {tab == 2 && (
                    <Button
                      onClick={async (e) => {
                        e.preventDefault();
                        const isValid = await trigger(["questions", "feeling"]);
                        if (isValid) {
                          setTab((prev) => prev + 1);
                        }
                      }}
                      variant="primary"
                      className="w-100"
                    >
                      {data?.question_btn_txt}
                    </Button>
                  )}
                  {tab == 3 && (
                    <ButtonWithLoading
                      variant="primary"
                      type="submit"
                      className="fw-500 font-dubai d-block w-100 mb-3 mb-md-0"
                      text={data?.desc_btn_text}
                      disabled={!isValid || !isDirty || isSubmitting}
                      isLoading={status == "pending"}
                      onClick={handleSubmit(onSubmit)}
                    />
                  )}
                  {tab == 3 && (
                    <Button
                      variant="outline"
                      className="px-5 w-100 mt-3"
                      onClick={() => setTab((prev) => prev - 1)}
                    >
                      {t("Back")}
                    </Button>
                  )}
                </div>
              </>
            )}
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </>
  );
};

export default HappinessMetter;
