import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import InputLabel from "../../Components/Form/InputLabel";
import OtpInput from "react-otp-input";
import Alert from "react-bootstrap/Alert";
import CustomButton from "../../Components/Global/customButton";
import { Controller } from "react-hook-form";
import useApi from "../../hooks/useApi";
import { AuthAPI } from "../../apis/authApi";
import FieldValidationText from "../../Components/Global/fieldValidationText";
import { useTranslation } from "react-i18next";
import useForm from "../../hooks/useForm";
import { Image } from "react-bootstrap";
import { selectLangState } from "../../redux/configurationSlice";
import { useDispatch, useSelector } from "react-redux";
import { handleToken } from "../../redux/authSlice";
import { emiratesIdForDB } from "../../utils/utilityFunctions";
import ButtonWithLoading from "../buttons/ButtonWithLoading";
import { toast } from "react-toastify";
const OtpVerification = ({ setCurrent, state, setState, type }) => {
  const { lang } = useSelector(selectLangState);
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const { mobile_no, email, emiratesId } = state;
  const [timer, setTimer] = useState(40);
  const [isResending, setIsResending] = useState(false);
  const [loading, invokeApi] = useApi();
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    setError,
    trigger,
    formState: { errors, isSubmitting, isDirty, isValid },
    handleChange,
  } = useForm({
    mode: "all",
  });

  const onSubmit = async (values) => {
    try {
      const data = {
        otp_type: type,
        company: "ESD",
        platform: "website",
        language: lang?.startsWith("ar") ? "ar" : "en",
        otp: values.otp,
      };
      if (emiratesId) {
        data.emirates_id = emiratesIdForDB(emiratesId);
      }
      if (mobile_no) {
        data.mobile_no = mobile_no;
      }
      if (email) {
        data.email = email;
      }
      let res = await invokeApi(() => AuthAPI.verifyOtp(data));
      dispatch(handleToken(res?.access_token));
      if (type === "REGISTRATION" && state?.status == "NEW_REGISTRATION") {
        setCurrent(2);
      } else {
        setCurrent(1);
        setState({
          mobile_no: null,
          email: null,
        });
        navigate("/setup-password");
      }
    } catch (error) {
      toast.error(error?.message);
      console.error("Error during OTP verification:", error);
    }
  };

  useEffect(() => {
    let intervalId;

    const startTimer = () => {
      intervalId = setInterval(() => {
        if (timer > 0) {
          setTimer((prevTimer) => prevTimer - 1);
        } else {
          clearInterval(intervalId);
        }
      }, 1000);
    };

    startTimer();

    return () => clearInterval(intervalId);
  }, [timer]);

  const handleResendClick = async () => {
    if (!isResending) {
      setIsResending(true);
      try {
        await invokeApi(() =>
          AuthAPI.sendOtp({
            otp_type: "FORGET_PASSWORD",
            company: "ESD",
            platform: "website",
            language: lang?.startsWith("ar") ? "ar" : "en",
            mobile_no: mobile_no,
          })
        );
        setTimer(40);
      } catch (error) {
        console.error("Error during OTP resend:", error);
      } finally {
        setIsResending(false);
      }
    }
  };

  return (
    <>
      <div className="bg-auth">
        <Image
          src="/images/bg-auth.png"
          alt="icon-uae-pass"
          className="d-inline-block align-middle me-2"
        />
      </div>
      <div className="auth d-flex align-items-center justify-content-center">
        <div className="center-content w-100">
          <h1 className="fw-700 text-white font-bukra">
            {t("Verify your")}
            <br /> {t("mobile number")}
            <div className="d-block fw-400 subTitle font-dubai text-grey-400 mb-4">
              {t("fourDigitVerificationText")}
              <span className="numberDirection">
                {`${mobile_no?.slice(0, 3)} ****** ${mobile_no?.slice(-3)}`}
              </span>
            </div>
            <span className="d-block fw-400 subTitle font-dubai text-grey-400 mb-0">
              {t("adminContactText")}.
            </span>
          </h1>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3">
              <InputLabel
                className="text-white"
                text={`${t("fourDigitCode")}`}
              />
              <Controller
                name="otp"
                control={control}
                rules={{
                  required: t("Otp required"),
                  minLength: {
                    value: 4,
                    message: t("Enter 4 digit otp"),
                  },
                }}
                render={({ field }) => (
                  <OtpInput
                    {...field}
                    value={field.value}
                    onChange={field.onChange}
                    numInputs={4}
                    containerStyle={"otpWrap"}
                    renderSeparator={<span></span>}
                    renderInput={(props) => <input {...props} />}
                    inputStyle="form-control text-white"
                    isInputNum
                    inputType="number"
                  />
                )}
              />
              {errors?.otp && (
                <FieldValidationText errorMessage={errors.otp?.message} />
              )}
            </Form.Group>
            <ButtonWithLoading
              variant="primary"
              type="submit"
              className="fw-500 font-dubai d-block w-100 mb-3 mb-md-0"
              text={t("Verify")}
              disabled={!isValid || !isDirty || isSubmitting || loading}
              isLoading={!isResending && loading}
            />
            <div className="d-flex gap-2 pt-4 fw-400 fs-16">
              <span className="text-white">{t("Code not received yet")}?</span>
              {timer > 0 ? (
                <>
                  <span className="text-grey-400 numberDirection ms-auto">
                    {timer} sec
                  </span>
                </>
              ) : (
                <span className="text-white">
                  <span
                    className="secondary-color text-decoration-none"
                    onClick={handleResendClick}
                    aria-disabled={isResending}
                  >
                    {t("Send again")}
                  </span>
                </span>
              )}
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default OtpVerification;
