import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import OfferCard from "../Global/OfferCard";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectLangState } from "../../redux/configurationSlice";

const NewOffers = ({ offers = [], refetch }) => {
  const { lang } = useSelector(selectLangState);
  const { t } = useTranslation();

  return (
    <section className="offer-section new-offers">
      <Container>
        <Row className="head g-0">
          <Col xs={7}>
            <h2 className="fw-700 font-bukra text-black title-lg">
              {t("New Offers")}
            </h2>
          </Col>
          <Col xs={5} className="d-flex justify-content-end">
            <div>
              <Link
                to="/offers/new_offers"
                className="primary-color text-decoration-none fw-500 link btn btn-outline"
              >
                {t("Show All")}
              </Link>
            </div>
          </Col>
          <Col xs={12} className="d-none d-md-block">
            <small className="fw-300 text-black text-info">
              {t(
                "Explore our latest selection of  offers, curated for your best benefit."
              )}
            </small>
          </Col>
        </Row>
        <Row xs={1} className="g-4">
          <Col>
            <Swiper
              key={lang?.startsWith("ar") ? "rtl" : "ltr"}
              dir={lang?.startsWith("ar") ? "rtl" : "ltr"}
              navigation={{
                prevEl: ".swiper-feature-prev",
                nextEl: ".swiper-feature-next",
              }}
              modules={[Navigation]}
              className="offer-swiper"
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 12,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 24,
                },
                1200: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
              }}
            >
              {offers?.map((offer, key) => (
                <SwiperSlide key={key}>
                  <OfferCard
                    to={`/merchant-detail/${offer?.merchant_id}`}
                    imageUrl={offer?.image_url_web}
                    offerTitle={offer?.title}
                    address={offer?.address}
                    category={offer?.outlet_name}
                    offerType={offer?.type}
                    discount={offer?.discount}
                    distance={offer?.distance}
                    rating={offer?.avg_rating}
                    outlet={offer?.outlet_name}
                    tags={offer?.tags}
                    isFavorite={offer?.is_favourite}
                    offer={offer}
                    valid_till={offer?.valid_till_date}
                    refetch={refetch}
                  />
                </SwiperSlide>
              ))}

              <div className="swiper-button-prev swiper-feature-prev"></div>
              <div className="swiper-button-next swiper-feature-next"></div>
            </Swiper>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default NewOffers;
