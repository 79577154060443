import { apiService } from "./configs/axiosConfig";
const webURL = process.env.REACT_APP_WEB_URL_API;
export const CategoryAPI = {
  getCategoriesFilter: async (data) => {
    const response = await apiService({
      url: "/filters",
      method: "POST",
      data: { ...data },
    });

    return response?.data;
  },
  getCategories: async (data) => {
    const response = await apiService(
      {
        url: "/web/categories",
        method: "POST",
        data: { ...data },
      },
      { baseURL: webURL }
    );
    return response.data;
  },
};
