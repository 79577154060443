import Card from "react-bootstrap/Card";
import { Link, useLocation } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";
import Image from "react-bootstrap/Image";
import { AuthAPI } from "../../apis/authApi";
import { useSelector } from "react-redux";
import { selectLangState } from "../../redux/configurationSlice";
import { MoengageService } from "../../services/moengage";

const OfferCard = ({
  to,
  imageUrl,
  offerTitle,
  address,
  category,
  offerType,
  discount,
  distance,
  rating,
  outlet,
  isFavorite = false,
  tags = [],
  offer = null,
  valid_till,
  showTravelIcons = false,
  refetch = () => {},
}) => {
  const location = useLocation();
  const { lang } = useSelector(selectLangState);
  const addFavorites = async () => {
    try {
      const data = {
        company: "ESD",
        language: lang?.startsWith("ar") ? "ar" : "en",
        platform: "website",
        outlet_id: offer?.outlet_id,
      };
      const res = await AuthAPI.addFavorites(data);
      MoengageService.addToFavourite({
        screen_name: location?.pathname?.includes("/home-member")
          ? "Home"
          : "Categories",
        merchant_name: offer?.title,
      });
      refetch();
    } catch (error) {
      console.log(error);
    }
  };
  const removeFavorites = async () => {
    try {
      const data = {
        company: "ESD",
        language: lang?.startsWith("ar") ? "ar" : "en",
        platform: "website",
        outlet_id: offer?.outlet_id,
      };
      const res = await AuthAPI.removeFavorites(data);
      MoengageService.removeFromFavourite({
        screen_name: location?.pathname?.includes("/home-member")
          ? "Home"
          : "Categories",
        merchant_name: offer?.title,
      });
      refetch();
    } catch (error) {
      console.log(error);
    }
  };

  const handleMerchantClick = (e) => {
    e.stopPropagation();
    MoengageService.visitMerchantDetailPage({
      merchant_name: offer?.title,
      merchant_id: offer?.merchant_id,
      outlet_name: offer?.outlet_name,
      outlet_id: offer?.outlet_id,
    });
  };
  return (
    <Card className="offer">
      <Link to={to} onClick={handleMerchantClick}>
        <div className="imgHolder">
          <Card.Img
            variant="top"
            src={imageUrl || "/images/placeholderImage.png"}
            alt="Offer Image"
            className="d-block img-fluid w-100"
          />
          {tags?.length > 0 && (
            <Stack direction="horizontal" gap={2}>
              {tags?.map((tag, key) => (
                <Badge
                  pill
                  key={key}
                  bg={tag?.text === "Featured" ? "light" : "primary"}
                  text={tag?.text === "Featured" ? "dark" : "white"}
                  className={tag?.text !== "Featured" && "dir-ltr"}
                >
                  {tag?.text}
                </Badge>
              ))}
            </Stack>
          )}
          <span
            className={`favIcon d-flex align-items-center justify-content-center rounded-circle ${
              isFavorite && "bg-primary"
            }`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              isFavorite ? removeFavorites() : addFavorites();
            }}
          >
            <svg
              width="19"
              height="16"
              viewBox="0 0 19 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.8836 1.55161C15.9708 0.60137 14.6918 0.0606976 13.3517 0.0586932C12.0106 0.0601965 10.7303 0.600582 9.81608 1.551L9.50451 1.86987L9.19295 1.551C7.37896 -0.339921 4.3257 -0.448513 2.37339 1.3084C2.28688 1.38629 2.20332 1.46717 2.12291 1.551C0.198613 3.56129 0.198613 6.66809 2.12291 8.67838L9.04665 15.7502C9.28619 15.9951 9.68537 16.0056 9.93825 15.7736C9.94653 15.766 9.95458 15.7582 9.96242 15.7502L16.8836 8.67838C18.8078 6.6683 18.8078 3.56168 16.8836 1.55161ZM15.971 7.83723H15.9704L9.50451 14.4423L3.03805 7.83723C1.568 6.30119 1.568 3.92758 3.03805 2.39154C4.37304 0.990141 6.62826 0.902272 8.07518 2.19525C8.14543 2.25803 8.21302 2.3235 8.27784 2.39154L9.04665 3.1771C9.30001 3.42091 9.70906 3.42091 9.96242 3.1771L10.7312 2.39214C12.0662 0.990749 14.3214 0.90288 15.7683 2.19586C15.8386 2.25864 15.9062 2.3241 15.971 2.39214C17.4538 3.93062 17.4647 6.30856 15.971 7.83723Z"
                fill="white"
              />
            </svg>
          </span>
          <Badge pill bg="secondary" className="distance dir-ltr">
            {distance} km
          </Badge>
        </div>
        <Card.Body>
          <div className="d-flex align-items-start">
            <Card.Title className="primary-color font-bukra fw-700 title pe-1">
              {offerTitle}
            </Card.Title>
          </div>
          <Card.Text className="mb-0">{address}</Card.Text>
          <div className="bottom-bar d-flex justify-content-between">
            {/*<Badge pill bg="secondary">
              {outlet}
            </Badge>*/}
            <span className="text-dark dir-ltr">{valid_till}</span>
            <div className="rating d-flex align-items-center ms-auto">
              <Image src="/images/Star.svg" alt="Star" className="me-1" />
              <span className="font-bukra fw-500">{rating}</span>
            </div>
          </div>
          {showTravelIcons && (
            <div className="travelIcons d-flex gap-2 mt-2">
              <span className="icon">
                <Image
                  src="/images/travel-car.svg"
                  alt="Car"
                  className="img-fluid"
                />
              </span>
              <span className="icon">
                <Image
                  src="/images/travel-cycle.svg"
                  alt="Cycle"
                  className="img-fluid"
                />
              </span>
              <span className="icon">
                <Image
                  src="/images/travel-walk.svg"
                  alt="Walk"
                  className="img-fluid"
                />
              </span>
              <span className="icon">
                <Image
                  src="/images/travel-train.svg"
                  alt="Train"
                  className="img-fluid"
                />
              </span>
            </div>
          )}
        </Card.Body>
      </Link>
    </Card>
  );
};

export default OfferCard;
