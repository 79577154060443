import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import OfferCard from "../Components/Global/OfferCard";
import Image from "react-bootstrap/Image";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { selectLangState } from "../redux/configurationSlice";
import { useSelector } from "react-redux";

import { Navigation, Pagination, EffectFade } from "swiper/modules";
import { HomeAPI } from "../apis/homeApi";
import { Fragment } from "react";
import PageLoader from "../Components/loaders/pageLoader";

const Categories = () => {
  const { lang } = useSelector(selectLangState);
  const { t } = useTranslation();
  const { data, refetch, isLoading } = useQuery({
    queryKey: [
      "get-all-categories",
      {
        company: "ESD",
        location_id: 0,
        language: lang?.startsWith("ar") ? "ar" : "en",
        platform: "WEB",
        lat: "25.205200541193324",
        lng: "55.27186452531266",
      },
    ],
    queryFn: ({ queryKey }) => HomeAPI.getCategories({ ...queryKey[1] }),
  });

  if (isLoading) return <PageLoader />;
  if (data?.length == 0)
    return (
      <div
        id="error-page"
        style={{ height: "60vh" }}
        className=" d-flex flex-column align-items-center justify-content-center"
      >
        <div className="row p-2 m-2  text-black  rounded-2">
          <h1>{t("Oops")}!</h1>
        </div>
        <div className="row pt-3 mt-2 mb-3 bg-warning shadow rounded-2">
          <p>
            <i>{t("No Categories found!")}</i>
          </p>
        </div>
      </div>
    );

  return (
    <>
      <div className="contentHolder">
        <Container>
          <h1 className="title-lg page-head primary-color fw-700 font-bukra">
            {t("All Categories")}
          </h1>

          <div className="all-offersWrap">
            {data &&
              data?.map((category, index) => (
                <Fragment key={index}>
                  {category?.section_identifier == "banner_section" ? (
                    <Swiper
                      navigation={{
                        prevEl: ".bnImages_Swiper-prev",
                        nextEl: ".bnImages_Swiper-next",
                      }}
                      pagination={{ clickable: true }}
                      modules={[Navigation, Pagination]}
                      className="bnImages_Swiper"
                    >
                      {category?.section_items?.map((banner, index) => (
                        <SwiperSlide key={banner?.slide_id}>
                          <div className="bnImage">
                            <Image
                              src={
                                banner?.banner_url ??
                                "/images/placeholderBanner.png"
                              }
                              alt="Banner Image"
                              className="d-block w-100 img-fluid"
                            />
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  ) : (
                    <div className="offers-category-row">
                      <div
                        className="head add d-flex d-md-inline-flex position-relative"
                        style={{ zIndex: "9" }}
                      >
                        <div className="d-flex align-items-center justify-content-between justify-content-md-start">
                          <h2 className="fw-700 font-bukra text-black title-lg flex-1">
                            {category?.category_name}
                            {/* <span className="catCount d-none d-md-block ms-3">(180)</span> */}
                          </h2>
                          {category?.show_all && (
                            <div className="ms-3">
                              <Link
                                to={{
                                  pathname: `/categories/${category?.filter_val}`,
                                  search: "?sort=nearest",
                                }}
                                state={{
                                  category_name: category?.category_name,
                                }}
                                className="primary-color text-decoration-none fw-500 link"
                              >
                                {t("Show All")}
                              </Link>
                            </div>
                          )}
                        </div>
                      </div>
                      <Swiper
                        key={lang?.startsWith("ar") ? "rtl" : "ltr"}
                        dir={lang?.startsWith("ar") ? "rtl" : "ltr"}
                        navigation={{
                          prevEl: ".swiper-automotive-prev",
                          nextEl: ".swiper-automotive-next",
                        }}
                        modules={[Navigation]}
                        className="offer-swiper add"
                        breakpoints={{
                          320: {
                            slidesPerView: 1,
                            spaceBetween: 12,
                          },
                          768: {
                            slidesPerView: 2,
                            spaceBetween: 24,
                          },
                          1200: {
                            slidesPerView: 4,
                            spaceBetween: 20,
                          },
                        }}
                      >
                        {category?.merchants?.map((offer, key) => (
                          <SwiperSlide key={offer}>
                            <OfferCard
                              to={`/merchant-detail/${offer?.merchant_id}`}
                              imageUrl={offer?.image_url}
                              offerTitle={offer?.title}
                              address={offer?.address}
                              category={offer?.outlet_name}
                              outlet={offer?.outlet_name}
                              offerType={offer?.type}
                              discount={offer?.discount}
                              distance={offer?.distance}
                              rating={offer?.avg_rating}
                              tags={offer?.tags}
                              isFavorite={offer?.is_favourite}
                              offer={offer}
                              valid_till={offer?.valid_till_date}
                              refetch={refetch}
                            />
                          </SwiperSlide>
                        ))}
                        <div className="swiper-button-prev swiper-automotive-prev"></div>
                        <div className="swiper-button-next swiper-automotive-next"></div>
                      </Swiper>
                    </div>
                  )}
                </Fragment>
              ))}
          </div>
        </Container>
      </div>
    </>
  );
};

export default Categories;
